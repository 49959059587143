import { Dayjs } from 'dayjs';

import dayjs from '../utils/dayjsInstance';
import DraftPick, { DraftPickData } from './DraftPick';
import Model, { ModelData } from './Model';

export interface DraftData extends ModelData {
  startTime: string;
  minutesPerPick: number;
  orderedDraftPicks: DraftPickData[];
}

export default class Draft extends Model {
  readonly startTime: Dayjs;
  readonly minutesPerPick: number;
  readonly orderedDraftPicks: DraftPick[];

  constructor(data: DraftData) {
    super(data);
    this.startTime = dayjs(data.startTime);
    this.minutesPerPick = data.minutesPerPick;
    this.orderedDraftPicks = data.orderedDraftPicks.map(
      (dpd) => new DraftPick(dpd)
    );
  }

  get hasStarted(): boolean {
    return this.startTime.isBefore(dayjs());
  }

  get nextPick(): DraftPick | undefined {
    return this.orderedDraftPicks.find((dp: DraftPick) => !dp.hasBeenUsed);
  }

  get hasEnded(): boolean {
    return this.orderedDraftPicks.every((dp) => dp.hasBeenUsed);
  }
}
