import { Dayjs } from 'dayjs';

import { MembershipCause } from '../shared/constants';
import dayjs from '../utils/dayjsInstance';
import Model, { ModelData } from './Model';
import Player, { PlayerData } from './Player';

export interface PlayerFactionMembershipData extends ModelData {
  player: PlayerData;
  membershipCause: MembershipCause;
  startDate: Dayjs;
  endDate?: Dayjs;
  points?: number;
}

export default class PlayerFactionMembership extends Model {
  readonly player: Player;
  readonly membershipCause: MembershipCause;
  readonly startDate: Dayjs;
  readonly endDate?: Dayjs;
  readonly points?: number;

  constructor(data: PlayerFactionMembershipData) {
    super(data);
    this.player = new Player(data.player);
    this.membershipCause = data.membershipCause;
    this.startDate = dayjs(data.startDate);
    this.endDate = data.endDate && dayjs(data.endDate);
    this.points = data.points;
  }
}
