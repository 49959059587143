import React, { ReactElement } from 'react';
import { Footer as AntdFooter } from 'antd/lib/layout/layout';
import { Row } from 'antd';
import { HeartFilled } from '@ant-design/icons';

import { CUSTOM_ANTD_THEME } from '../utils';

interface iProps {}

const Footer: React.FC<iProps> = (): ReactElement | null => {
  return (
    <AntdFooter>
      <Row justify="center">
        <div>
          Made with{' '}
          <HeartFilled style={{ color: CUSTOM_ANTD_THEME.successColor }} /> by{' '}
          <a target="__blank" href="https://JKJones.me">
            Jemar Jones
          </a>
          .
        </div>
      </Row>
    </AntdFooter>
  );
};

export default Footer;
