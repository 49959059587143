import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Spin, Table, Tooltip } from 'antd';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import api from '../api';
import PlayerModel from '../api/Player';
import Season from '../api/Season';
import { handleError } from '../utils';
import PlayerDetailButton from './PlayerDetailButton';

interface iProps {
  leagueId?: string;
  canAddPlayers?: boolean;
  addPlayer?: (selectedPlayer: PlayerModel) => void;
  showRankings?: boolean;
  seasonToRankBy?: Season;
}

const PlayerList: React.FC<iProps> = ({
  leagueId,
  canAddPlayers,
  addPlayer,
  showRankings = false,
  seasonToRankBy,
}): ReactElement | null => {
  const [players, setPlayers] = useState<PlayerModel[]>();
  const [search, setSearch] = useState('');

  useEffect(() => {
    api
      .getPlayers({
        availableInLeagueId: leagueId,
        seasonIdToRankBy: seasonToRankBy?.id,
      })
      .then((players) => {
        setPlayers(players);
      })
      .catch(handleError);
  }, [leagueId, seasonToRankBy]);

  const getAddPlayerFn = useCallback(
    (player: PlayerModel): (() => void) | undefined => {
      if (!addPlayer) return undefined;

      return (): void => {
        addPlayer(player);
      };
    },
    [addPlayer]
  );

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const normalizeTextForSearch = useCallback((text: string) => {
    return text.toLowerCase().replace(/\s/g, '');
  }, []);

  const filteredPlayers = useMemo(() => {
    return players?.filter((player) =>
      normalizeTextForSearch(player.name).includes(
        normalizeTextForSearch(search)
      )
    );
  }, [search, players, normalizeTextForSearch]);

  const columns = useMemo(() => {
    const actionColumns = canAddPlayers
      ? [
          {
            title: '',
            key: 'action',
            width: 100,
            render: (_text: string, player: PlayerModel): ReactElement => {
              const handleAddPlayer = getAddPlayerFn(player);

              return (
                <Popconfirm
                  title="Confirm player pickup?"
                  onConfirm={handleAddPlayer}
                  disabled={!handleAddPlayer}
                >
                  <Button type="primary" disabled={!handleAddPlayer}>
                    Pickup
                  </Button>
                </Popconfirm>
              );
            },
          },
        ]
      : [];

    const rankingColumns = showRankings
      ? [
          {
            title: 'Ranking',
            dataIndex: 'rank',
          },
          {
            title: (
              <span>
                Total Points{' '}
                <Tooltip title="Total faction points since the beginning of season 8">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            ),
            dataIndex: 'points',
          },
        ]
      : [];
    return [
      ...rankingColumns,
      {
        title: 'Player',
        dataIndex: 'name',
        render: (_text: string, player: PlayerModel): ReactElement => {
          return <PlayerDetailButton player={player} />;
        },
      },
      ...actionColumns,
    ];
  }, [canAddPlayers, getAddPlayerFn, showRankings]);

  if (!players) {
    return <Spin />;
  }

  return (
    <div>
      <Input.Search
        className="player-list-search"
        placeholder="Search players"
        onChange={onSearch}
        onSearch={setSearch}
      />
      <div className="scroll-gradient">
        <div className="player-list">
          <Table
            showHeader={showRankings}
            className="player-list__table"
            dataSource={filteredPlayers}
            columns={columns}
            rowKey="id"
            rowClassName="player-list__row"
            pagination={false}
            scroll={{ y: '65vh' }}
            locale={{ emptyText: 'No players found' }}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerList;
