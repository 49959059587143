import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';

import PlayerList from '../components/PlayerList';
import api from '../api';
import Season from '../api/Season';
import { AntDMenuInfo, handleError } from '../utils';

const ALL_TIME = 'all_time';

interface iProps {}

const PlayerRankings: React.FC<iProps> = (): ReactElement | null => {
  const [selectedSeason, setSelectedSeason] = useState<Season | null>();
  const [seasons, setSeasons] = useState<Season[]>();

  useEffect(() => {
    api
      .getSeasons()
      .then((seasons) => {
        setSeasons(seasons);
        setSelectedSeason(null);
      })
      .catch(handleError);
  }, []);

  const handleMenuItemClick = useCallback(
    (info: AntDMenuInfo) => {
      if (!seasons) {
        return null;
      }

      if (info.key === ALL_TIME) {
        setSelectedSeason(null);
      } else {
        setSelectedSeason(seasons.find(({ id }) => id === info.key));
      }
    },
    [seasons]
  );

  if (!seasons || selectedSeason === undefined) {
    return <Spin />;
  }

  return (
    <Row justify="center">
      <Col xs={24} md={20}>
        <Typography.Title level={1}>MOTY Player Rankings</Typography.Title>
        <Dropdown
          overlay={
            <Menu onClick={handleMenuItemClick}>
              <Menu.Item key={ALL_TIME}>All Time</Menu.Item>
              {seasons.map((season) => (
                <Menu.Item key={season.id}>Season {season.number}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button type="link">
            {selectedSeason ? `Season ${selectedSeason.number}` : 'All Time'}{' '}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Tooltip title="Rankings only consider matches played season 8 and forward">
          <InfoCircleOutlined />
        </Tooltip>
        <Card>
          <PlayerList
            showRankings
            seasonToRankBy={selectedSeason ?? undefined}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PlayerRankings;
