import { Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE } from '..';
import { useAppContext } from '../hooks/useAppContext';
import { Role } from '../shared/constants';

interface iProps {
  roles: Role[];
}

const RolesGuard: React.FC<iProps> = ({
  roles,
  children,
}): ReactElement | null => {
  const history = useHistory();
  const {
    state: { user },
  } = useAppContext();
  const [satisfiesRoleRequirement, setSatisfiesRoleRequirement] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (user) {
      setSatisfiesRoleRequirement(
        roles.some((role) => user.roles.includes(role))
      );
    }
  }, [user, roles]);

  useEffect(() => {
    if (satisfiesRoleRequirement === false) {
      history.push(ROUTE.HOME);
    }
  }, [satisfiesRoleRequirement, history]);

  if (satisfiesRoleRequirement) {
    return <>{children}</>;
  } else {
    return <Spin />;
  }
};

export default RolesGuard;
