import Model, { ModelData } from './Model';

export interface PlayerData extends ModelData {
  name: string;
  points?: number;
  rank?: number;
  imageUrl?: string;
}

export default class Player extends Model {
  readonly name: string;
  readonly points?: number;
  readonly rank?: number;
  readonly imageUrl?: string;

  constructor(data: PlayerData) {
    super(data);
    this.name = data.name;
    this.points = data.points;
    this.rank = data.rank;
    this.imageUrl = data.imageUrl;
  }
}
