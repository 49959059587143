import React, { ReactElement, useCallback, useMemo } from 'react';
import { Col, Row, Space, Spin, Table } from 'antd';
import { Link } from 'react-router-dom';

import League from '../api/League';
import Faction from '../api/Faction';
import api from '../api';
import { usePagination } from '../hooks/PaginationHooks';
import Paginated from '../api/Paginated';
import Trade from '../api/Trade';
import { handleError } from '../utils';
import { ROUTE, routePath } from '..';

interface iProps {
  league: League;
  faction?: Faction;
}

const Trades: React.FC<iProps> = ({ league, faction }): ReactElement | null => {
  const { items: trades, paginationProps } = usePagination(
    useCallback(
      (page, pageSize): Promise<Paginated<Trade> | void> => {
        return api
          .getTrades(league.id, faction?.id, { page, pageSize })
          .catch(handleError);
      },
      [league.id, faction?.id]
    )
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
      },
      {
        title: 'Offered by',
        dataIndex: ['offeredBy', 'username'],
      },
      {
        title: 'Offered to',
        dataIndex: ['offeredTo', 'username'],
      },
      {
        title: '',
        key: 'view',
        render: (_text: string, trade: Trade): ReactElement => {
          return (
            <Link
              className="ant-btn ant-btn-default"
              to={routePath(ROUTE.TRADE, {
                leagueId: league.id,
                tradeId: trade.id,
              })}
            >
              View
            </Link>
          );
        },
      },
    ];
  }, [league.id]);

  if (!trades) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" className="space-wrapper">
          <Table
            dataSource={trades}
            columns={columns}
            rowKey="id"
            locale={{ emptyText: 'No trades found' }}
            scroll={{ x: 393 }}
            pagination={false}
          />
          {paginationProps.loadingPage ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : null}
          <Row justify="center">{paginationProps.loadMoreElement}</Row>
        </Space>
      </Col>
    </Row>
  );
};

export default Trades;
