import { AxiosError } from 'axios';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';

import { ROUTE, routePath } from '..';
import api from '../api';
import Draft from '../api/Draft';
import LeagueModel from '../api/League';
import { useAppContext } from '../hooks/useAppContext';
import LeagueCommisioner from '../components/LeagueCommisioner';
import LeagueFactionCapacity from '../components/LeagueFactionCapacity';
import { handleError } from '../utils';
import Faction from '../api/Faction';
import DiscordLink from '../components/DiscordLink';
import useTabHashRouting from '../hooks/useActiveTabKey';
import Trades from '../components/Trades';

interface Params {
  leagueId: string;
}

const League: React.FC = (): ReactElement | null => {
  const tabProps = useTabHashRouting('overview');
  const { leagueId } = useParams<Params>();
  const [league, setLeague] = useState<LeagueModel>();
  const [draft, setDraft] = useState<Draft | null>();
  const {
    state: { user },
  } = useAppContext();

  const refreshLeague = useCallback(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  useEffect(() => {
    refreshLeague();
  }, [refreshLeague]);

  useEffect(() => {
    api
      .getDraft(leagueId)
      .then((draft) => {
        setDraft(draft);
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError && error.response?.status === 404) {
          setDraft(null);
        } else {
          handleError(error);
        }
      });
  }, [leagueId]);

  const actionRow = useMemo(() => {
    const items: JSX.Element[] = [];

    const isYourLeague = league && user && league.commissioner.id === user.id;
    if (user && league?.canUserJoin(user)) {
      items.push(
        <Link
          key="join-league"
          className="ant-btn ant-btn-primary"
          to={routePath(ROUTE.CREATE_FACTION, { leagueId })}
        >
          Join League
        </Link>
      );
    } else if (!draft && isYourLeague) {
      items.push(
        <Link
          key="set-draft"
          className="ant-btn ant-btn-primary"
          to={routePath(ROUTE.SET_DRAFT, { leagueId })}
        >
          Set Draft Date
        </Link>
      );
    } else if (draft) {
      if (!draft.hasEnded) {
        items.push(
          <Link
            key="go-to-draft"
            className="ant-btn ant-btn-primary"
            to={routePath(ROUTE.DRAFT, { leagueId })}
          >
            Go to draft
          </Link>
        );
      } else {
        items.push(
          <Link
            key="view-draft"
            className="ant-btn ant-btn-secondary"
            to={routePath(ROUTE.DRAFT, { leagueId })}
          >
            View draft
          </Link>
        );
      }
    } else if (league) {
      items.push(
        <Typography.Text key="please-wait">
          Please wait for {league.commissioner.username} to set the draft date.
        </Typography.Text>
      );
    }

    items.push(
      <Link
        key="view-players"
        className="ant-btn ant-btn-primary"
        to={routePath(ROUTE.LEAGUE_PLAYERS, { leagueId })}
      >
        View Available Players
      </Link>
    );

    return (
      <Row align="middle" justify="space-between">
        {items.map((action) => action)}
      </Row>
    );
  }, [league, user, draft, leagueId]);

  const getRemoveFactionFn = useCallback(
    (faction: Faction): (() => void) => {
      return (): void => {
        api
          .removeFaction(leagueId, faction.id)
          .then(refreshLeague)
          .catch(handleError);
      };
    },
    [leagueId, refreshLeague]
  );

  const factionColumns: ColumnsType<Faction> = useMemo(() => {
    const removeColumn =
      !draft && league?.commissioner.id === user?.id
        ? [
            {
              title: '',
              key: 'removeAction',
              width: 100,
              render: (
                _text: string,
                faction: Faction
              ): ReactElement | null => {
                if (!draft && league?.commissioner.id === user?.id) {
                  const removeFaction = getRemoveFactionFn(faction);
                  return (
                    <Popconfirm
                      title={`Are you sure you want to remove ${faction.name}?`}
                      onConfirm={removeFaction}
                    >
                      <Button>Remove Faction</Button>
                    </Popconfirm>
                  );
                }

                return null;
              },
            },
          ]
        : [];
    const pointsDisplay = draft?.hasEnded
      ? [
          {
            title: 'Points',
            dataIndex: 'pointsDisplay',
            key: 'pointsDisplay',
          },
        ]
      : [];
    return [
      {
        title: 'Rank',
        key: 'id',
        width: 70,
        render: (_text: string, _faction: Faction, index: number): number => {
          return index + 1;
        },
      },
      {
        title: 'Faction',
        dataIndex: 'name',
        key: 'name',
      },
      ...pointsDisplay,
      {
        title: '',
        key: 'action',
        width: 100,
        render: (_text: string, faction: Faction): ReactElement => {
          return (
            <Link
              className="ant-btn ant-btn-secondary"
              to={routePath(ROUTE.FACTION, {
                leagueId: leagueId,
                factionId: faction.id,
              })}
            >
              View
            </Link>
          );
        },
      },
      ...removeColumn,
    ];
  }, [leagueId, league, user, draft, getRemoveFactionFn]);

  if (!league || draft === undefined || !user) {
    return <Spin />;
  }

  // TODO: Maybe display roster sizes?
  return (
    <Row justify="center" className="league">
      <Col xs={24} md={16}>
        <Card
          className="tabbed-card"
          title={
            <Space direction="horizontal">
              {league.name}
              <DiscordLink link={league.discordLink} />
            </Space>
          }
          extra={
            <Row>
              {draft && !draft.hasStarted ? (
                draft.startTime.isBefore(dayjs().add(12, 'hours')) ? (
                  <Countdown
                    key="countdown"
                    title="Draft starts in"
                    value={draft.startTime.valueOf()}
                  />
                ) : (
                  <Typography.Text>
                    Draft Date:{' '}
                    {draft.startTime.format('MMMM Do YYYY, h:mm a z')}
                  </Typography.Text>
                )
              ) : (
                <Typography.Text>
                  <LeagueCommisioner league={league} currentUser={user} />
                </Typography.Text>
              )}
            </Row>
          }
        >
          <Tabs
            {...tabProps}
            tabBarExtraContent={`Season: ${league.season.number}`}
          >
            <Tabs.TabPane key="overview" tab="Overview">
              <Space
                className="space-wrapper"
                direction="vertical"
                size="large"
              >
                {!draft ? (
                  <Row justify="start" align="middle">
                    <Col xs={6} sm={4}>
                      <Typography.Text className="league__factions-label">
                        Factions:
                      </Typography.Text>
                    </Col>
                    <Col xs={18} sm={20}>
                      <LeagueFactionCapacity league={league} />
                    </Col>
                  </Row>
                ) : null}
                {league.factions.length ? (
                  <Table
                    dataSource={league.factions}
                    columns={factionColumns}
                    rowKey="id"
                    scroll={{ x: 345 }}
                    pagination={false}
                  />
                ) : null}
                {actionRow}
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane key="trades" tab="Trades">
              <Trades league={league} />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default League;
