import React, { ReactElement, useEffect, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import useFirebaseUser from '../hooks/useFirebaseUser';
import { useWithRedirect } from '../hooks/redirection';
import { ROUTE } from '..';
import api from '../api';
import { useAppContext } from '../hooks/useAppContext';
import { handleError } from '../utils';

interface iProps {}

const AuthGuard: React.FC<iProps> = ({ children }): ReactElement | null => {
  const {
    state: { user },
    setUser,
  } = useAppContext();
  const firebaseUser = useFirebaseUser();
  const location = useLocation();
  const history = useHistory();
  const withRedirect = useWithRedirect();

  const needsUser = useMemo(
    () => !user && location.pathname !== ROUTE.CREATE_ACCOUNT,
    [user, location]
  );
  useEffect(() => {
    if (firebaseUser?.emailVerified && needsUser) {
      api
        .getUser(firebaseUser.uid)
        .then((user) => {
          setUser(user);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 404) {
            history.push(withRedirect(ROUTE.CREATE_ACCOUNT));
          } else {
            handleError(error);
          }
        });
    }
  }, [
    firebaseUser?.emailVerified,
    needsUser,
    firebaseUser?.uid,
    setUser,
    history,
    withRedirect,
  ]);

  const notAuthorized =
    firebaseUser === null || (firebaseUser && !firebaseUser.emailVerified);
  if (notAuthorized) {
    return <Redirect to={withRedirect(ROUTE.AUTH)} />;
  } else if (firebaseUser === undefined || needsUser) {
    // TOOD: Should be a loader.
    return null;
  } else {
    return <>{children}</>;
  }
};

export default AuthGuard;
