import React, { ReactElement } from 'react';
import { Form } from 'antd';
import { FormInstance, FormItemProps } from 'antd/lib/form';

type iProps<iFormData> = FormItemProps<iFormData> & {
  form: FormInstance<iFormData>;
};

/**
 * Same as antd Form.Item, but only shows input errors when a field has been
 * touched. To be used in conjunction with @useFormWithDisableableSubmit.
 */
const InputFormItem = <iFormData extends unknown>({
  form,
  ...props
}: iProps<iFormData>): ReactElement | null => {
  const isTouched = !!(props.name && form.isFieldTouched(props.name));

  return (
    <Form.Item
      {...props}
      help={isTouched ? props.help : ''}
      validateStatus={isTouched ? props.validateStatus : ''}
    />
  );
};

export default InputFormItem;
