import firebase from 'firebase';
import { useState, useEffect } from 'react';

import useFirebase from './useFirebase';

const useFirebaseUser = (): firebase.User | null | undefined => {
  const firebaseInstance = useFirebase();
  const [user, setUser] = useState<firebase.User | null | undefined>(
    firebaseInstance.auth().currentUser || undefined
  );

  useEffect(() => {
    const authUnsubscribe = firebaseInstance.auth().onAuthStateChanged(setUser);

    return authUnsubscribe;
  }, [firebaseInstance]);

  return user;
};

export default useFirebaseUser;
