import React, { ReactElement } from 'react';
import { Card, Col, Row } from 'antd';

import MatchList from '../components/MatchList';

interface iProps {}

const AllMatches: React.FC<iProps> = (): ReactElement | null => {
  return (
    <Row justify="center">
      <Col xs={24} md={20}>
        <Card title="Matches">
          <MatchList />
        </Card>
      </Col>
    </Row>
  );
};

export default AllMatches;
