import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Affix,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Typography,
  Grid,
  Drawer,
} from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import cloneDeep from 'lodash.clonedeep';
import classNames from 'classnames';

import dayjs from '../utils/dayjsInstance';
import api from '../api';
import DraftModel from '../api/Draft';
import DraftPickModel, { DraftPickData } from '../api/DraftPick';
import useEventListener from '../hooks/useEventListener';
import { DraftPicksUpdatedEvent, EventType } from '../shared/events';
import DraftPlayerList from '../components/DraftPlayerList';
import Player from '../api/Player';
import { useAppContext } from '../hooks/useAppContext';
import League from '../api/League';
import DraftRosters from '../components/DraftRosters';
import DraftRounds from '../components/DraftRounds';
import { handleError, makeNotificationSound } from '../utils';
import { ROUTE, routePath } from '..';
import BackLink from '../components/BackLink';
import DraftPick from '../api/DraftPick';

const { useBreakpoint } = Grid;

interface Params {
  leagueId: string;
}

const Draft: React.FC = (): ReactElement | null => {
  const { leagueId } = useParams<Params>();
  const {
    state: { user },
  } = useAppContext();
  const [draft, setDraft] = useState<DraftModel>();
  const [league, setLeague] = useState<League>();
  const [draftHeader, setDraftHeader] = useState<HTMLDivElement | null>(null);
  const [draftHeaderHeight, setDraftHeaderHeight] = useState<
    number | undefined
  >(undefined);
  const screens = useBreakpoint();
  const [playerListOpen, setPlayerListOpen] = useState<boolean>(false);

  useEffect(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  useEffect(() => {
    api
      .getDraft(leagueId)
      .then((draft) => {
        setDraft(draft);
      })
      .catch(handleError);
  }, [leagueId]);

  const draftHeaderRef = useCallback((node: HTMLDivElement) => {
    setDraftHeader(node);
  }, []);

  useEffect(() => {
    if (draftHeader) {
      const resizeObserver = new ResizeObserver((entry) =>
        setDraftHeaderHeight(entry[0].contentRect.height)
      );
      resizeObserver.observe(draftHeader);

      return (): void => {
        resizeObserver.unobserve(draftHeader);
      };
    }
  }, [draftHeader]);

  const openDraftPlayerList = useCallback(() => {
    setPlayerListOpen(true);
  }, []);

  const closeDraftPlayerList = useCallback(() => {
    setPlayerListOpen(false);
  }, []);

  const nextPick = useMemo(() => draft?.nextPick, [draft]);
  const nextPickIsYours = useMemo(() => {
    return !!(nextPick && user && nextPick.faction.manager.id === user.id);
  }, [nextPick, user]);

  useEffect(() => {
    if (nextPickIsYours && draft?.hasStarted) {
      makeNotificationSound();
    }
  }, [draft?.hasStarted, nextPickIsYours]);

  const draftPlayer = useCallback(
    (pick: DraftPickModel, playerToDraft: Player) => {
      return api
        .makeDraftPick(leagueId, pick.id, playerToDraft.id)
        .then(closeDraftPlayerList)
        .catch(handleError);
    },
    [leagueId, closeDraftPlayerList]
  );

  useEventListener(
    draft?.id,
    EventType.DRAFT_PICKS_UPDATED,
    useCallback((event: DraftPicksUpdatedEvent) => {
      const updatedPicks = (event.updatedDraftPicks as DraftPickData[]).map(
        (pick: DraftPickData) => new DraftPick(pick)
      );

      setDraft((draft) => {
        if (draft) {
          const newDraft = cloneDeep(draft);
          updatedPicks.forEach((updatedPick) => {
            const index = newDraft.orderedDraftPicks.findIndex(
              (pick) => pick.id === updatedPick.id
            );
            newDraft.orderedDraftPicks[index] = updatedPick;
          });
          return newDraft;
        }
      });
    }, [])
  );

  if (!draft || !league || !user) {
    return <Spin />;
  }

  const draftCountdown = nextPick?.deadline ? (
    <Countdown
      className={classNames('draft__pick-countdown', {
        'draft__pick-countdown--youre-on-the-clock': nextPickIsYours,
      })}
      title={`${nextPick.faction.name} on the clock`}
      value={nextPick.deadline.valueOf()}
    />
  ) : !draft.hasStarted ? (
    draft.startTime.isBefore(dayjs().add(12, 'hours')) ? (
      <Countdown title="Starts in" value={draft.startTime.valueOf()} />
    ) : (
      <Typography.Text className="ant-statistic draft__starts-at">
        <div className="ant-statistic-title">Start time:</div>
        <span className="ant-statistic-content">
          {draft?.startTime.format('MMMM Do YYYY, h:mm a z')}
        </span>
      </Typography.Text>
    )
  ) : draft.hasEnded ? (
    <Countdown title="Draft ended" value={dayjs().valueOf()} />
  ) : null;

  const draftPlayerList = (
    <DraftPlayerList
      availableInLeagueId={leagueId}
      draftPlayer={draftPlayer}
      draft={draft}
      pick={nextPickIsYours && draft.hasStarted ? nextPick : undefined}
    />
  );

  return (
    <Col className="draft">
      <Row>
        <Col span={24}>
          <Affix>
            <div ref={draftHeaderRef}>
              <Card size="small">
                <Row justify="space-between" align="middle">
                  <Space align="baseline">
                    <BackLink to={routePath(ROUTE.LEAGUE, { leagueId })} />
                    <Typography.Title level={5}>
                      {league.name} Draft
                    </Typography.Title>
                  </Space>

                  {draftCountdown}
                </Row>
              </Card>
            </div>
          </Affix>
        </Col>
      </Row>
      {draftHeaderHeight === undefined ? (
        <Spin />
      ) : (
        <Row gutter={32}>
          <Col span={24} sm={12} xl={8}>
            <DraftRounds
              draft={draft}
              nextPick={nextPick}
              pickPlayer={openDraftPlayerList}
              currentUserId={user.id}
              draftHeaderHeight={draftHeaderHeight}
            />
          </Col>
          {screens.sm ? (
            <Col span={12} xl={8}>
              <Affix
                className="draft-players-container"
                offsetTop={draftHeaderHeight}
              >
                {draftPlayerList}
              </Affix>
            </Col>
          ) : null}
          <Drawer
            className="draft__drawer"
            width={338}
            // The way antd's breakpoints work, this means we'll only show this drawer if
            // we're below a "small" screen size
            visible={playerListOpen && !screens.sm}
            onClose={closeDraftPlayerList}
          >
            {draftCountdown}
            {draftPlayerList}
          </Drawer>
          {screens.xl ? (
            <Col span={8}>
              <Affix
                className="draft-rosters-container"
                offsetTop={draftHeaderHeight}
              >
                <DraftRosters draftPicks={draft.orderedDraftPicks} />
              </Affix>
            </Col>
          ) : null}
        </Row>
      )}
    </Col>
  );
};

export default Draft;
