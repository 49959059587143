import React, { ReactElement, useCallback } from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface iProps {
  to?: string;
}

const BackLink: React.FC<iProps> = ({ to }): ReactElement | null => {
  const history = useHistory();

  const goBack = useCallback(() => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  }, [history, to]);

  return (
    <Button className="back-link" onClick={goBack}>
      <ArrowLeftOutlined />
    </Button>
  );
};

export default BackLink;
