import React, { ReactElement, useCallback, useState } from 'react';
import { Button, Col, Form, Input, Row, Card, Grid } from 'antd';

import api from '../../api';
import useFormWithDisableableSubmit from '../../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../../components/InputFormItem';
import { handleError } from '../../utils';
import PlayerList from '../../components/PlayerList';

const { useBreakpoint } = Grid;

type FormData = {
  playerName: string;
};

const CreatePlayer: React.FC = (): ReactElement | null => {
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({});
  const [creatingPlayer, setCreatingPlayer] = useState(false);
  const screens = useBreakpoint();

  const handleCreatePlayer = useCallback(
    (values: FormData) => {
      const { playerName } = values;

      setCreatingPlayer(true);
      api
        .createPlayer(playerName)
        .then(() => {
          form.resetFields();
        })
        .catch(handleError)
        .finally(() => {
          setCreatingPlayer(false);
        });
    },
    [form]
  );

  return (
    <Row className="create-player" justify="center">
      <Col>
        <Card>
          <Form
            form={form}
            layout={screens.sm ? 'inline' : 'vertical'}
            onFinish={handleCreatePlayer}
            onChange={handleFormChange}
          >
            <InputFormItem
              form={form}
              label="Player name"
              name="playerName"
              rules={[{ required: true }]}
            >
              <Input disabled={creatingPlayer} />
            </InputFormItem>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!canSubmit}
                loading={creatingPlayer}
              >
                Create Player
              </Button>
            </Form.Item>
          </Form>
          {!creatingPlayer ? <PlayerList /> : null}
        </Card>
      </Col>
    </Row>
  );
};

export default CreatePlayer;
