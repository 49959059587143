import React, { ReactElement } from 'react';

import League from '../api/League';
import User from '../api/User';

interface iProps {
  league: League;
  currentUser: User;
}

const LeagueCommisioner: React.FC<iProps> = ({
  league,
  currentUser,
}): ReactElement | null => {
  return (
    <>
      {league.commissioner.id === currentUser.id
        ? 'Your league'
        : `${league.commissioner.username}'s league`}
    </>
  );
};

export default LeagueCommisioner;
