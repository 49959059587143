import { Card } from 'antd';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import Paginated from '../api/Paginated';
import LoadMore from '../components/LoadMore';

interface NextPage {
  number: number;
  itemCount: number;
}
export interface PaginationProps {
  nextPage?: {
    number: number;
    itemCount: number;
  };
  loadingPage: boolean;
  loadMoreElement: React.ReactNode;
}

type UsePaginationReturn<Item extends object> = {
  items?: Item[];
  paginationProps: PaginationProps;
};

export const usePagination = <Item extends object>(
  getItems: (page: number, pageSize: number) => Promise<Paginated<Item> | void>,
  options: {
    pageSize?: number;
    ready?: boolean;
  } = {}
): UsePaginationReturn<Item> => {
  const pageSize = options.pageSize ?? 10;
  const ready = options.ready ?? true;
  const [items, setItems] = useState<Item[]>();
  const [nextPage, setNextPage] = useState<NextPage | undefined>({
    number: 1,
    itemCount: pageSize,
  });
  const [loadingPage, setLoadingPage] = useState(false);

  const handlePagination = useCallback((paginatedResponse: Paginated<Item>) => {
    setItems((items) => [...(items || []), ...paginatedResponse.items]);
    if (
      paginatedResponse.meta.currentPage < paginatedResponse.meta.totalPages
    ) {
      setNextPage({
        number: paginatedResponse.meta.currentPage + 1,
        itemCount: Math.min(
          paginatedResponse.meta.totalItems -
            paginatedResponse.meta.itemCount *
              paginatedResponse.meta.currentPage,
          paginatedResponse.meta.itemsPerPage
        ),
      });
    } else {
      setNextPage(undefined);
    }
  }, []);

  const loadPage = useCallback(
    (page: number) => {
      setLoadingPage(true);
      getItems(page, pageSize)
        .then((paginatedLeagues) => {
          if (paginatedLeagues) {
            handlePagination(paginatedLeagues);
          }
        })
        .finally(() => {
          setLoadingPage(false);
        });
    },
    [getItems, pageSize, handlePagination, setLoadingPage]
  );

  useEffect(() => {
    if (ready) {
      setItems(undefined);
      loadPage(1);
    }
  }, [loadPage, ready]);

  const loadMore: (() => void) | undefined = useMemo(() => {
    if (!nextPage) {
      return undefined;
    } else {
      return (): void => {
        loadPage(nextPage.number);
      };
    }
  }, [loadPage, nextPage]);

  const paginationProps = useMemo(() => {
    return {
      nextPage,
      loadingPage,
      loadMoreElement: loadMore ? <LoadMore loadMore={loadMore} /> : null,
    };
  }, [nextPage, loadingPage, loadMore]);

  return {
    items,
    paginationProps,
  };
};

interface LoadingCard {
  isLoadingCard: true;
}

type LoadingFacadesReturn<Item extends object> = {
  itemsWithLoadingFacades?: (Item | LoadingCard)[];
  renderWithLoadingFacade: (item: LoadingCard | Item) => React.ReactNode;
};

export const useLoadingFacades = <Item extends object>(
  renderItem: (item: Item) => React.ReactNode,
  items: Item[] | undefined,
  paginationProps: PaginationProps,
  loadingCardClassName: string
): LoadingFacadesReturn<Item> => {
  const renderWithLoadingFacade = useCallback(
    (item: Item | LoadingCard): React.ReactNode => {
      if ('isLoadingCard' in item) {
        return <Card loading className={loadingCardClassName} />;
      }

      return renderItem(item);
    },
    [renderItem, loadingCardClassName]
  );

  const itemsWithLoadingFacades = useMemo(() => {
    if (!paginationProps.loadingPage) {
      return items;
    } else if (paginationProps.nextPage?.itemCount) {
      return [
        ...(items || []),
        ...[...Array(paginationProps.nextPage.itemCount)].map(
          (): LoadingCard => ({
            isLoadingCard: true,
          })
        ),
      ];
    }
  }, [items, paginationProps.loadingPage, paginationProps.nextPage?.itemCount]);

  return {
    itemsWithLoadingFacades,
    renderWithLoadingFacade,
  };
};
