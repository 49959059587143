import React, { ReactElement, useCallback, useMemo } from 'react';
import { Col, Row, Space, Spin, Table, Tooltip } from 'antd';
import { Dayjs } from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';

import League from '../api/League';
import Faction from '../api/Faction';
import api from '../api';
import { usePagination } from '../hooks/PaginationHooks';
import Paginated from '../api/Paginated';
import { displayEnumValue, handleError } from '../utils';
import PlayerFactionMembership from '../api/PlayerFactionMembership';
import PlayerDetailButton from './PlayerDetailButton';

interface iProps {
  league: League;
  faction: Faction;
}

const FactionMembershipHistory: React.FC<iProps> = ({
  league,
  faction,
}): ReactElement | null => {
  const { items: playerFactionMemberships, paginationProps } = usePagination(
    useCallback(
      (page, pageSize): Promise<Paginated<PlayerFactionMembership> | void> => {
        return api
          .getPlayerFactionMemberships(league.id, faction.id, {
            page,
            pageSize,
          })
          .catch(handleError);
      },
      [league.id, faction.id]
    ),
    {
      pageSize: 12,
    }
  );

  const renderDate = useCallback((date: Dayjs) => {
    return date ? (
      <Tooltip title={date.format('LLL z')}>{date.format('LL')}</Tooltip>
    ) : (
      '--'
    );
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Player',
        dataIndex: 'player',
        render: (
          _text: string,
          playerFactionMembership: PlayerFactionMembership
        ): ReactElement => {
          return <PlayerDetailButton player={playerFactionMembership.player} />;
        },
      },
      {
        title: 'Points',
        dataIndex: 'points',
      },
      {
        title: 'Membership Cause',
        dataIndex: 'membershipCause',
        render: displayEnumValue,
      },
      {
        title: (
          <span>
            Membership Active After{' '}
            <Tooltip title="Player's are only eligible to accumulate points for a faction at 12:00AM PST the day after their membership begins.">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        ),
        dataIndex: 'startDate',
        render: renderDate,
      },
      {
        title: 'Membership Ended',
        dataIndex: 'endDate',
        render: renderDate,
      },
    ];
  }, [renderDate]);

  if (!playerFactionMemberships) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" className="space-wrapper">
          <Table
            dataSource={playerFactionMemberships}
            columns={columns}
            rowKey="id"
            locale={{ emptyText: 'No player history' }}
            pagination={false}
            scroll={{ x: 449 }}
          />
          {paginationProps.loadingPage ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : null}
          <Row justify="center">{paginationProps.loadMoreElement}</Row>
        </Space>
      </Col>
    </Row>
  );
};

export default FactionMembershipHistory;
