import { Button, Row, Table, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import React, { ReactElement, useCallback, useMemo } from 'react';

import api from '../api';
import Match from '../api/Match';
import Paginated from '../api/Paginated';
import Player from '../api/Player';
import { usePagination } from '../hooks/PaginationHooks';
import { Outcome } from '../shared/constants';
import { handleError } from '../utils';

interface iProps {
  onView?: (match: Match) => void;
  featuringPlayer?: Player;
}

const MatchList: React.FC<iProps> = ({
  onView,
  featuringPlayer,
}): ReactElement | null => {
  const { items: matches, paginationProps } = usePagination(
    useCallback(
      (page, pageSize): Promise<Paginated<Match> | void> => {
        return api
          .getMatches({ page, pageSize }, featuringPlayer?.id)
          .catch(handleError);
      },
      [featuringPlayer?.id]
    )
  );

  const getOnViewFn = useCallback(
    (match: Match): (() => void) | undefined => {
      if (!onView) {
        return undefined;
      }

      return (): void => {
        onView(match);
      };
    },
    [onView]
  );

  const columns = useMemo(() => {
    const actionColumns = onView
      ? [
          {
            title: '',
            key: 'action',
            render: (_text: string, match: Match): ReactElement => {
              const onClick = getOnViewFn(match);

              return <Button onClick={onClick}>View</Button>;
            },
          },
        ]
      : [];
    return [
      {
        title: 'Date',
        dataIndex: 'date',
        render: (date: Dayjs): string => {
          return date.format('LL');
        },
      },
      {
        title: 'Division',
        dataIndex: 'divisionName',
      },
      {
        title: 'Match Type',
        dataIndex: 'matchTypeName',
      },
      {
        title: 'Competitors',
        key: 'competitors',
        render: (_text: string, match: Match): ReactElement => {
          if (match.matchCompetitors.length <= 5) {
            return (
              <>
                {match.matchCompetitors.map((matchCompetitor) => (
                  <Row key={matchCompetitor.id}>
                    <Typography.Text>
                      {matchCompetitor.competitor.name}
                    </Typography.Text>
                  </Row>
                ))}
              </>
            );
          } else {
            return (
              <Typography.Text>
                {match.matchCompetitors.length} competitors
              </Typography.Text>
            );
          }
        },
      },
      {
        title: 'Winner',
        key: 'winner',
        render: (_text: string, match: Match): string => {
          const winner = match.matchCompetitors.find(
            (matchCompetitor) =>
              matchCompetitor.outcomes &&
              matchCompetitor.outcomes.includes(Outcome.WON)
          );

          if (winner) {
            return winner.competitor.name;
          } else {
            return '--';
          }
        },
      },
      ...(featuringPlayer
        ? [
            {
              title: 'Points earned',
              dataIndex: 'pointsEarned',
            },
          ]
        : []),
      ...actionColumns,
    ];
  }, [onView, featuringPlayer, getOnViewFn]);

  return (
    <>
      <Table
        loading={!matches}
        dataSource={matches}
        columns={columns}
        rowKey="id"
        pagination={false}
        locale={{ emptyText: 'No matches yet' }}
        scroll={{ x: 525 }}
      />
      {paginationProps.loadMoreElement}
    </>
  );
};

export default MatchList;
