import { Card, Col, Row, Space, Spin, Typography } from 'antd';
import { AxiosError } from 'axios';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ROUTE, routePath } from '..';
import api from '../api';
import Draft from '../api/Draft';
import League from '../api/League';
import Player from '../api/Player';
import BackLink from '../components/BackLink';
import PlayerList from '../components/PlayerList';
import { useAppContext } from '../hooks/useAppContext';
import { handleError } from '../utils';

interface Params {
  leagueId: string;
}

const LeaguePlayers: React.FC = (): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const { leagueId } = useParams<Params>();
  const history = useHistory();
  const [league, setLeague] = useState<League>();
  const [draft, setDraft] = useState<Draft | null>();

  useEffect(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  useEffect(() => {
    api
      .getDraft(leagueId)
      .then((draft) => {
        setDraft(draft);
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError && error.response?.status === 404) {
          setDraft(null);
        } else {
          handleError(error);
        }
      });
  }, [leagueId]);

  const ownedFaction = useMemo(() => {
    return league?.factions.find((faction) => faction.manager.id === user?.id);
  }, [user, league?.factions]);

  const addPlayer = useCallback(
    (player: Player) => {
      if (!ownedFaction) {
        handleError(
          new Error(
            'Cannot add a player because you arent a member of this league.'
          )
        );
        return;
      }

      api
        .addPlayer({
          leagueId,
          factionId: ownedFaction.id,
          playerId: player.id,
        })
        .then(() => {
          history.push(
            routePath(ROUTE.FACTION, { leagueId, factionId: ownedFaction.id })
          );
        })
        .catch(handleError);
    },
    [leagueId, ownedFaction, history]
  );

  if (!league) {
    return <Spin />;
  }

  return (
    <Row justify="center" className="league-players">
      <Col xs={24} sm={16} xl={8}>
        <Card
          title={
            <Space>
              <BackLink to={routePath(ROUTE.LEAGUE, { leagueId })} />
              Available Players
            </Space>
          }
        >
          <Row justify="center" className="league-players__note">
            <Typography.Text>
              Note: Player's are only eligible to accumulate points for a
              faction at 12:00AM PST the day after they've been added to a
              faction.
            </Typography.Text>
          </Row>
          <PlayerList
            addPlayer={!league.season.isEnded ? addPlayer : undefined}
            leagueId={leagueId}
            canAddPlayers={!!ownedFaction && !!draft?.hasEnded}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default LeaguePlayers;
