import React, { ReactElement, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
  Grid,
} from 'antd';

import { ROUTE, routePath } from '..';
import api from '../api';
import useFormWithDisableableSubmit from '../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../components/InputFormItem';
import { handleError } from '../utils';
import DiscordLink from '../components/DiscordLink';

const { useBreakpoint } = Grid;

interface Params {
  leagueId: string;
}

type FormData = {
  factionName: string;
};

const CreateFaction: React.FC = (): ReactElement | null => {
  const { leagueId } = useParams<Params>();
  const history = useHistory();
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({});
  const [creatingFaction, setCreatingFaction] = useState(false);
  const screens = useBreakpoint();

  const handleCreateFaction = useCallback(
    (values: FormData) => {
      const { factionName } = values;

      setCreatingFaction(true);
      api
        .createFaction(leagueId, factionName)
        .then(() => {
          history.push(routePath(ROUTE.LEAGUE, { leagueId }));
        })
        .catch((error) => {
          setCreatingFaction(false);
          handleError(error);
        });
    },
    [leagueId, history]
  );

  return (
    <>
      <Card className="create-faction__discord">
        <Space align="center" className="create-faction__discord__spacer">
          <Typography.Text strong>
            Make sure to join the Discord once you join this league!
          </Typography.Text>{' '}
          <DiscordLink />
        </Space>
      </Card>
      <Row justify="center" className="page__vertically-centered">
        <Col className={screens.sm ? 'inline-form-container' : undefined}>
          <Form
            form={form}
            layout={screens.sm ? 'inline' : 'vertical'}
            onFinish={handleCreateFaction}
            onChange={handleFormChange}
          >
            <InputFormItem
              form={form}
              label="Faction name"
              name="factionName"
              rules={[{ required: true }]}
            >
              <Input disabled={creatingFaction} />
            </InputFormItem>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!canSubmit}
                loading={creatingFaction}
              >
                Create Faction
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CreateFaction;
