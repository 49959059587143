import { Card, Spin, Table, Tabs } from 'antd';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import groupBy from 'lodash.groupby';

import DraftPick from '../api/DraftPick';
import Faction from '../api/Faction';
import { useAppContext } from '../hooks/useAppContext';
import PlayerDetailButton from './PlayerDetailButton';

interface iProps {
  draftPicks: DraftPick[];
}

const DraftRosters: React.FC<iProps> = ({
  draftPicks,
}): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const [defaultFaction, setDefaultFaction] = useState<Faction | undefined>();

  const draftRosters = useMemo(() => {
    return groupBy(draftPicks, (pick) => pick.faction.id);
  }, [draftPicks]);

  const factions: Faction[] = useMemo(() => {
    return Object.values(draftRosters).map(
      (draftRoster) => draftRoster[0].faction
    );
  }, [draftRosters]);

  useEffect(() => {
    if (user) {
      // Autoselect your own faction, or if youre a spectator then just
      // the first faction in the list.
      setDefaultFaction(
        factions.find((f) => f.manager.id === user.id) || factions[0]
      );
    }
  }, [user, factions]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Pick',
        dataIndex: 'round',
      },
      {
        title: 'Player',
        dataIndex: 'id', // Not going to display, just for uniqueness
        render: (_text: string, draftPick: DraftPick): ReactNode => {
          if (!draftPick.playerFactionMembership) {
            return '--';
          } else {
            return (
              <PlayerDetailButton
                player={draftPick.playerFactionMembership.player}
              />
            );
          }
        },
      },
    ];
  }, []);

  if (!defaultFaction) {
    return <Spin />;
  }

  return (
    <div className="scroll-gradient">
      <Card title="Faction Rosters" className="draft-rosters">
        <Tabs tabPosition="left" defaultActiveKey={defaultFaction.id}>
          {factions.map((faction) => (
            <Tabs.TabPane key={faction.id} tab={faction.name}>
              <Table
                className="draft-rosters__table"
                dataSource={draftRosters[faction.id]}
                columns={columns}
                rowKey="id"
                pagination={false}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Card>
    </div>
  );
};

export default DraftRosters;
