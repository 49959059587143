import { Role } from '../shared/constants';
import Model, { ModelData } from './Model';

export interface UserData extends ModelData {
  username: string;
  roles: Role[];
}

export default class User extends Model {
  readonly username: string;
  readonly roles: Role[];

  constructor(data: UserData) {
    super(data);
    this.username = data.username;
    this.roles = data.roles;
  }

  get isAdmin(): boolean {
    return this.roles.includes(Role.ADMIN);
  }
}
