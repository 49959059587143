import { Col, Row, Typography, Menu, Dropdown, Button, Spin } from 'antd';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';

import api from '../api';
import League from '../api/League';
import Paginated from '../api/Paginated';
import Season from '../api/Season';
import LeagueList from '../components/LeagueList';
import { usePagination } from '../hooks/PaginationHooks';
import { useAppContext } from '../hooks/useAppContext';
import { AntDMenuInfo, handleError } from '../utils';

const MyLeagues: React.FC = (): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const [selectedSeason, setSelectedSeason] = useState<Season>();
  const [seasons, setSeasons] = useState<Season[]>();

  useEffect(() => {
    api
      .getSeasons()
      .then((seasons) => {
        setSeasons(seasons);
        setSelectedSeason(seasons[0]);
      })
      .catch(handleError);
  }, []);

  const { items: leagues, paginationProps } = usePagination(
    useCallback(
      (page, pageSize): Promise<Paginated<League> | void> => {
        return api
          .getLeagues(selectedSeason?.id, user?.id, { page, pageSize })
          .catch(handleError);
      },
      [user, selectedSeason]
    ),
    { ready: !!selectedSeason }
  );

  const handleMenuItemClick = useCallback(
    (info: AntDMenuInfo) => {
      if (!seasons) {
        return null;
      }

      setSelectedSeason(seasons.find(({ id }) => id === info.key));
    },
    [seasons]
  );

  if (!seasons || !selectedSeason) {
    return <Spin />;
  }

  return (
    <Row justify="center" className="my-leagues">
      <Col xs={24} sm={16} md={12} lg={10}>
        <Typography.Title level={1}>My Leagues</Typography.Title>
        <Dropdown
          overlay={
            <Menu onClick={handleMenuItemClick}>
              {seasons.map((season) => (
                <Menu.Item key={season.id}>Season {season.number}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button type="link">
            Season {selectedSeason.number} <DownOutlined />
          </Button>
        </Dropdown>
        <LeagueList leagues={leagues} paginationProps={paginationProps} />
      </Col>
    </Row>
  );
};

export default MyLeagues;
