import { useCallback, useEffect, useState } from 'react';
import { FormInstance, useForm } from 'antd/lib/form/Form';

// This nonesense isnt returned from antd
type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

const useFormWithDisableableSubmit = <FormData extends object>(
  initialFormData: RecursivePartial<FormData>
): [FormInstance<FormData>, boolean, () => void] => {
  const [form] = useForm<FormData>();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const checkIfCanSubmit = useCallback(() => {
    form
      .validateFields()
      .then(() => {
        setCanSubmit(true);
      })
      .catch(() => {
        setCanSubmit(false);
      });
  }, [form]);

  useEffect(() => {
    if (!form.isFieldsTouched()) {
      form.setFieldsValue(initialFormData);
      checkIfCanSubmit();
    }
  }, [form, initialFormData, checkIfCanSubmit]);

  return [form, canSubmit, checkIfCanSubmit];
};

export default useFormWithDisableableSubmit;
