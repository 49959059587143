import { Outcome } from '../shared/constants';
import Competitor, { CompetitorData } from './Competitor';
import Model, { ModelData } from './Model';

export interface MatchCompetitorData extends ModelData {
  competitor: CompetitorData;
  score?: number;
  outcomes?: Outcome[];
  pointsAllocated?: number;
}

export default class MatchCompetitor extends Model {
  readonly competitor: Competitor;
  readonly score?: number;
  readonly outcomes?: Outcome[];
  readonly pointsAllocated?: number;

  constructor(data: MatchCompetitorData) {
    super(data);
    this.competitor = new Competitor(data.competitor);
    this.score = data.score;
    this.outcomes = data.outcomes;
    this.pointsAllocated = data.pointsAllocated;
  }
}
