import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Dayjs } from 'dayjs';
import firebase from 'firebase';

import { Division, MatchType, Outcome } from '../shared/constants';
import firebaseUtil from '../utils/firebase';
import Competitor, { CompetitorData } from './Competitor';
import Draft, { DraftData } from './Draft';
import DraftPick, { DraftPickData } from './DraftPick';
import Faction, { FactionData } from './Faction';
import League, { LeagueData } from './League';
import Match, { MatchData } from './Match';
import Paginated, { PaginatedData } from './Paginated';
import Player, { PlayerData } from './Player';
import PlayerFactionMembership, {
  PlayerFactionMembershipData,
} from './PlayerFactionMembership';
import Season, { SeasonData } from './Season';
import Trade, { TradeData } from './Trade';
import User, { UserData } from './User';
import UserNotification, { UserNotificationData } from './UserNotification';

const firebaseInstance = firebaseUtil.firebase;

export interface RequestOptions {
  overrideUser?: firebase.User;
}

interface PaginationOptions {
  page?: number;
  pageSize?: number;
}

interface UpdateMatchCompetitorData {
  competitorId: string;
  score?: number;
  outcomes?: Outcome[];
}
interface UpdateMatchData {
  date: Dayjs;
  division: Division;
  matchType: MatchType;
  competitors: UpdateMatchCompetitorData[];
}

class Api {
  CURRENT_SEASON: string = 'current';

  private axiosInstance: AxiosInstance;
  private firebaseUser?: firebase.User | null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: '/api/',
    });

    firebaseInstance.auth().onAuthStateChanged((user: firebase.User | null) => {
      this.firebaseUser = user;
    });
  }

  async createUser(
    username: string,
    requestOptions?: RequestOptions
  ): Promise<User> {
    const response = await this.axiosInstance.post<UserData>(
      '/users',
      {
        username,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new User(response.data);
  }

  async getUser(id: string): Promise<User> {
    const response = await this.axiosInstance.get<UserData>(
      `/users/${id}`,
      await this.getRequestConfig()
    );

    return new User(response.data);
  }

  async getNotificationsForUser(userId: string): Promise<UserNotification[]> {
    const response = await this.axiosInstance.get<UserNotificationData[]>(
      `/users/${userId}/notifications`,
      await this.getRequestConfig()
    );

    return response.data.map(
      (userNotificationData) => new UserNotification(userNotificationData)
    );
  }

  async getSeasons(): Promise<Season[]> {
    const response = await this.axiosInstance.get<SeasonData[]>(
      `/seasons`,
      await this.getRequestConfig()
    );

    return response.data.map((season) => new Season(season));
  }

  async getLeagues(
    seasonId?: string,
    forUserId?: string,
    { page = 1, pageSize = 10 }: PaginationOptions = {}
  ): Promise<Paginated<League>> {
    const queryParams = new URLSearchParams();
    queryParams.set('page', page.toString());
    queryParams.set('pageSize', pageSize.toString());
    if (seasonId) {
      queryParams.set('seasonId', seasonId);
    }
    if (forUserId) {
      queryParams.set('forUserId', forUserId);
    }
    const queryString = `?${queryParams.toString()}`;
    const response = await this.axiosInstance.get<PaginatedData<LeagueData>>(
      `/leagues${queryString}`,
      await this.getRequestConfig()
    );

    return new Paginated(response.data, League);
  }

  async getLeague(leagueId: string): Promise<League> {
    const response = await this.axiosInstance.get<LeagueData>(
      `/leagues/${leagueId}`,
      await this.getRequestConfig()
    );

    return new League(response.data);
  }

  async createLeague(
    leagueData: {
      name: string;
      maxFactionCount: number;
      rosterSpotsPerFaction: number;
      hidden: boolean;
    },
    requestOptions?: RequestOptions
  ): Promise<League> {
    const response = await this.axiosInstance.post<LeagueData>(
      `/leagues`,
      leagueData,
      await this.getRequestConfig(requestOptions)
    );

    return new League(response.data);
  }

  async createFaction(
    leagueId: string,
    name: string,
    requestOptions?: RequestOptions
  ): Promise<Faction> {
    const response = await this.axiosInstance.post<FactionData>(
      `/leagues/${leagueId}/factions`,
      {
        name,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new Faction(response.data);
  }

  async removeFaction(leagueId: string, factionId: string): Promise<Faction> {
    const response = await this.axiosInstance.delete<FactionData>(
      `/leagues/${leagueId}/factions/${factionId}`,
      await this.getRequestConfig()
    );

    return new Faction(response.data);
  }

  async getFaction(leagueId: string, factionId: string): Promise<Faction> {
    const response = await this.axiosInstance.get<FactionData>(
      `/leagues/${leagueId}/factions/${factionId}`,
      await this.getRequestConfig()
    );

    return new Faction(response.data);
  }

  async createDraft(
    leagueId: string,
    startTime: Dayjs,
    minutesPerPick: number,
    requestOptions?: RequestOptions
  ): Promise<Draft> {
    const response = await this.axiosInstance.post<DraftData>(
      `/leagues/${leagueId}/draft`,
      {
        startTime: startTime.toISOString(),
        minutesPerPick,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new Draft(response.data);
  }

  async getDraft(
    leagueId: string,
    requestOptions?: RequestOptions
  ): Promise<Draft> {
    const response = await this.axiosInstance.get<DraftData>(
      `/leagues/${leagueId}/draft`,
      await this.getRequestConfig(requestOptions)
    );

    return new Draft(response.data);
  }

  async createPlayer(
    playerName: string,
    requestOptions?: RequestOptions
  ): Promise<Player> {
    const response = await this.axiosInstance.post<PlayerData>(
      `/players`,
      {
        name: playerName,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new Player(response.data);
  }

  async createCompetitor(
    competitorData: {
      name?: string;
      playerIds: string[];
    },
    requestOptions?: RequestOptions
  ): Promise<Competitor> {
    const response = await this.axiosInstance.post<CompetitorData>(
      `/competitors`,
      competitorData,
      await this.getRequestConfig(requestOptions)
    );

    return new Competitor(response.data);
  }

  async getCompetitors(): Promise<Competitor[]> {
    const response = await this.axiosInstance.get<CompetitorData[]>(
      `/competitors`,
      await this.getRequestConfig()
    );

    return response.data.map((competitor) => new Competitor(competitor));
  }

  async createMatch(
    matchData: UpdateMatchData,
    requestOptions?: RequestOptions
  ): Promise<Match> {
    const response = await this.axiosInstance.post<MatchData>(
      `/matches`,
      {
        date: matchData.date.toISOString(),
        division: matchData.division,
        matchType: matchData.matchType,
        competitors: matchData.competitors,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new Match(response.data);
  }

  async updateMatch(
    matchId: string,
    matchData: UpdateMatchData,
    requestOptions?: RequestOptions
  ): Promise<Match> {
    const response = await this.axiosInstance.put<MatchData>(
      `/matches/${matchId}`,
      {
        date: matchData.date.toISOString(),
        division: matchData.division,
        matchType: matchData.matchType,
        competitors: matchData.competitors,
      },
      await this.getRequestConfig(requestOptions)
    );

    return new Match(response.data);
  }

  async deleteMatch(
    matchId: string,
    requestOptions?: RequestOptions
  ): Promise<Match> {
    const response = await this.axiosInstance.delete<MatchData>(
      `/matches/${matchId}`,
      await this.getRequestConfig(requestOptions)
    );

    return new Match(response.data);
  }

  async getMatches(
    { page = 1, pageSize = 10 }: PaginationOptions = {},
    featuringPlayerId?: string
  ): Promise<Paginated<Match>> {
    const queryParams = new URLSearchParams();
    queryParams.set('page', page.toString());
    queryParams.set('pageSize', pageSize.toString());
    if (featuringPlayerId) {
      queryParams.set('featuringPlayerId', featuringPlayerId);
    }
    const queryString = `?${queryParams.toString()}`;

    const response = await this.axiosInstance.get<PaginatedData<MatchData>>(
      `/matches${queryString}`,
      await this.getRequestConfig()
    );

    return new Paginated(response.data, Match);
  }

  async getPlayers(
    options: {
      availableInLeagueId?: string;
      seasonIdToRankBy?: string;
    } = {}
  ): Promise<Player[]> {
    const queryParams = new URLSearchParams();
    if (options.availableInLeagueId) {
      queryParams.set('availableInLeagueId', options.availableInLeagueId);
    }
    if (options.seasonIdToRankBy) {
      queryParams.set('seasonIdToRankBy', options.seasonIdToRankBy);
    }
    const queryString = `?${queryParams.toString()}`;

    const response = await this.axiosInstance.get<PlayerData[]>(
      `/players${queryString}`,
      await this.getRequestConfig()
    );

    return response.data.map((player) => new Player(player));
  }

  async makeDraftPick(
    leagueId: string,
    pickId: string,
    playerId: string
  ): Promise<DraftPick> {
    const response = await this.axiosInstance.put<DraftPickData>(
      `/leagues/${leagueId}/draft/picks/${pickId}`,
      {
        playerId,
      },
      await this.getRequestConfig()
    );

    return new DraftPick(response.data);
  }

  async addPlayer(data: {
    leagueId: string;
    factionId: string;
    playerId: string;
  }): Promise<PlayerFactionMembership> {
    const response = await this.axiosInstance.post<PlayerFactionMembershipData>(
      `/leagues/${data.leagueId}/factions/${data.factionId}/player_memberships`,
      {
        playerId: data.playerId,
      },
      await this.getRequestConfig()
    );

    return new PlayerFactionMembership(response.data);
  }

  async dropPlayer(data: {
    leagueId: string;
    factionId: string;
    playerFactionMembershipId: string;
  }): Promise<PlayerFactionMembership> {
    const response = await this.axiosInstance.delete<PlayerFactionMembershipData>(
      `/leagues/${data.leagueId}/factions/${data.factionId}/player_memberships/${data.playerFactionMembershipId}`,
      await this.getRequestConfig()
    );

    return new PlayerFactionMembership(response.data);
  }

  async getPlayerFactionMemberships(
    leagueId: string,
    factionId: string,
    { page = 1, pageSize = 12 }: PaginationOptions = {}
  ): Promise<Paginated<PlayerFactionMembership>> {
    const queryParams = new URLSearchParams();
    queryParams.set('page', page.toString());
    queryParams.set('pageSize', pageSize.toString());
    queryParams.set('factionId', factionId);
    const queryString = `?${queryParams.toString()}`;
    const response = await this.axiosInstance.get<
      PaginatedData<PlayerFactionMembershipData>
    >(
      `/leagues/${leagueId}/factions/${factionId}/player_memberships${queryString}`,
      await this.getRequestConfig()
    );

    return new Paginated(response.data, PlayerFactionMembership);
  }

  async getFactionMatches(
    leagueId: string,
    factionId: string,
    { page = 1, pageSize = 10 }: PaginationOptions = {}
  ): Promise<Paginated<Match>> {
    const queryParams = new URLSearchParams();
    queryParams.set('page', page.toString());
    queryParams.set('pageSize', pageSize.toString());
    queryParams.set('factionId', factionId);
    const queryString = `?${queryParams.toString()}`;
    const response = await this.axiosInstance.get<PaginatedData<MatchData>>(
      `/leagues/${leagueId}/factions/${factionId}/matches${queryString}`,
      await this.getRequestConfig()
    );

    return new Paginated(response.data, Match);
  }

  async offerTrade(data: {
    leagueId: string;
    expiryTime: Dayjs;
    offeringFromFactionId: string;
    requestingFromFactionId: string;
    offeringPlayerMembershipIds: string[];
    requestingPlayerMembershipIds: string[];
  }): Promise<Trade> {
    const response = await this.axiosInstance.post<TradeData>(
      `/leagues/${data.leagueId}/trades`,
      {
        expiryTime: data.expiryTime.toISOString(),
        offeringFromFactionId: data.offeringFromFactionId,
        requestingFromFactionId: data.requestingFromFactionId,
        offeringPlayerMembershipIds: data.offeringPlayerMembershipIds,
        requestingPlayerMembershipIds: data.requestingPlayerMembershipIds,
      },
      await this.getRequestConfig()
    );

    return new Trade(response.data);
  }

  async getTrade(leagueId: string, tradeId: string): Promise<Trade> {
    const response = await this.axiosInstance.get<TradeData>(
      `/leagues/${leagueId}/trades/${tradeId}`,
      await this.getRequestConfig()
    );

    return new Trade(response.data);
  }

  async updateTrade(data: {
    leagueId: string;
    tradeId: string;
    approved: boolean;
  }): Promise<Trade> {
    const response = await this.axiosInstance.put<TradeData>(
      `/leagues/${data.leagueId}/trades/${data.tradeId}`,
      {
        approved: data.approved,
      },
      await this.getRequestConfig()
    );

    return new Trade(response.data);
  }

  async getTrades(
    leagueId: string,
    involvingFactionId?: string,
    { page = 1, pageSize = 10 }: PaginationOptions = {}
  ): Promise<Paginated<Trade>> {
    const queryParams = new URLSearchParams();
    queryParams.set('page', page.toString());
    queryParams.set('pageSize', pageSize.toString());
    if (involvingFactionId) {
      queryParams.set('involvingFactionId', involvingFactionId);
    }
    const queryString = `?${queryParams.toString()}`;
    const response = await this.axiosInstance.get<PaginatedData<TradeData>>(
      `/leagues/${leagueId}/trades${queryString}`,
      await this.getRequestConfig()
    );

    return new Paginated(response.data, Trade);
  }

  private async getRequestConfig(
    requestOptions?: RequestOptions
  ): Promise<AxiosRequestConfig> {
    const user = requestOptions?.overrideUser || this.firebaseUser;
    if (user) {
      const idToken = await user.getIdToken();
      return { headers: { Authorization: `Bearer ${idToken}` } };
    } else {
      return {};
    }
  }
}

export default new Api();
