import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Drawer,
  Card,
} from 'antd';
import { Dayjs } from 'dayjs';

import dayjs from '../../utils/dayjsInstance';
import api from '../../api';
import Competitor from '../../api/Competitor';
import useFormWithDisableableSubmit from '../../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../../components/InputFormItem';
import { Division, MatchType, Outcome } from '../../shared/constants';
import { displayEnumValue, filterOption, handleError } from '../../utils';
import MatchList from '../../components/MatchList';
import DatePicker from '../../components/DatePicker';
import MatchModel from '../../api/Match';

type FormData = {
  date: Dayjs;
  division: Division;
  matchType: MatchType;
  competitorIds: string[];
  outcomes?: Outcome[];
} & {
  [competitorId: string]: number | Outcome[];
};

const Match: React.FC = (): ReactElement | null => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [match, setMatch] = useState<MatchModel>();
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({});
  const [submittingMatch, setSubmittingMatch] = useState(false);
  const [competitors, setCompetitors] = useState<Competitor[]>();
  const [currentMatchType, setCurrentMatchType] = useState<
    MatchType | undefined
  >(form.getFieldValue('matchType'));
  const [currentCompetitorIds, setCurrentCompetitorIds] = useState<
    string[] | undefined
  >(form.getFieldValue('competitorIds'));

  useEffect(() => {
    api
      .getCompetitors()
      .then((competitors) => {
        setCompetitors(competitors);
      })
      .catch(handleError);
  }, []);

  const prevMatchRef = useRef<MatchModel | undefined>();
  useEffect(() => {
    if (match && prevMatchRef.current !== match) {
      const scoreValues = match.matchCompetitors.reduce(
        (accScoreVals, matchCompetitor) => {
          return {
            ...accScoreVals,
            [matchCompetitor.competitor.id]: matchCompetitor.score,
          };
        },
        {}
      );
      const outcomeValues = match.matchCompetitors.reduce(
        (accOutcomesVals, matchCompetitor) => {
          return {
            ...accOutcomesVals,
            [`${matchCompetitor.competitor.id}_outcomes`]: matchCompetitor
              .outcomes?.length
              ? matchCompetitor.outcomes
              : undefined,
          };
        },
        {}
      );

      form.setFieldsValue({
        date: match.date,
        division: match.division,
        matchType: match.matchType,
        competitorIds: match.matchCompetitors.map(
          (matchCompetitor) => matchCompetitor.competitor.id
        ),
        ...scoreValues,
        ...outcomeValues,
      } as FormData);
    }
    setCurrentCompetitorIds(form.getFieldValue('competitorIds'));
    setCurrentMatchType(form.getFieldValue('matchType'));
    prevMatchRef.current = match;
  }, [match, form]);

  const closeDrawer = useCallback(() => {
    setMatch(undefined);
    setDrawerOpen(false);
    form.resetFields();
  }, [form]);

  const deleteMatch = useCallback(() => {
    if (!match) {
      handleError(new Error('No match to delete.'));
      return;
    }

    api
      .deleteMatch(match.id)
      .then(() => {
        form.resetFields();
        window.location.reload();
      })
      .catch(handleError);
  }, [match, form]);

  const handleSubmitMatch = useCallback(
    (values: FormData) => {
      const {
        date: unadjustedDate,
        division,
        matchType,
        competitorIds,
      } = values;
      // All matches will be said to have taken place at 12:00AM PST
      const date = dayjs(unadjustedDate)
        .tz('America/Los_Angeles')
        .startOf('day');

      const competitors = competitorIds.map((id) => ({
        competitorId: id,
        score: (values[id] ?? undefined) as number | undefined,
        outcomes: values[`${id}_outcomes`] as Outcome[] | undefined,
      }));

      setSubmittingMatch(true);
      const submitMatch = match
        ? api.updateMatch(match.id, {
            date,
            division,
            matchType,
            competitors,
          })
        : api.createMatch({
            date,
            division,
            matchType,
            competitors,
          });

      submitMatch
        .then(() => {
          form.resetFields();
          setMatch(undefined);
          setDrawerOpen(false);
          setCurrentMatchType(undefined);
          setCurrentCompetitorIds(undefined);
          window.location.reload();
        })
        .finally(() => {
          setSubmittingMatch(false);
        })
        .catch(handleError);
    },
    [form, match]
  );

  const onMatchTypeChange = useCallback(() => {
    setCurrentMatchType(form.getFieldValue('matchType'));
    handleFormChange();
  }, [form, handleFormChange]);

  const currentCompetitors = useMemo(() => {
    return (
      competitors &&
      currentCompetitorIds?.map(
        (id) => competitors.find((competitor) => competitor.id === id)!
      )
    );
  }, [competitors, currentCompetitorIds]);

  const onCompetitorsChange = useCallback(() => {
    setCurrentCompetitorIds(form.getFieldValue('competitorIds'));
    handleFormChange();
  }, [form, handleFormChange]);

  const openMatch = useCallback((match: MatchModel): void => {
    setMatch(match);
    setDrawerOpen(true);
  }, []);

  const expandDrawer = useCallback((): void => {
    setDrawerOpen(true);
  }, []);

  if (!competitors) {
    return <Spin />;
  }

  return (
    <Row justify="space-around" className="admin-match">
      <Col xs={24} lg={16}>
        <Card>
          <Button
            className="admin-match__add-match"
            type="primary"
            disabled={drawerOpen}
            loading={submittingMatch}
            onClick={expandDrawer}
          >
            Add Match
          </Button>
        </Card>
        <MatchList onView={openMatch} />
      </Col>
      <Drawer width={338} visible={drawerOpen} onClose={closeDrawer}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmitMatch}
          onChange={handleFormChange}
        >
          <InputFormItem
            form={form}
            label="Date"
            name="date"
            rules={[{ required: true }]}
          >
            <DatePicker
              className="admin-match__date-picker"
              format="MMMM Do YYYY"
              disabled={submittingMatch}
              onChange={handleFormChange}
            />
          </InputFormItem>
          <InputFormItem
            form={form}
            label="Division"
            name="division"
            rules={[{ required: true }]}
          >
            <Select
              disabled={submittingMatch}
              onChange={handleFormChange}
              showSearch
              allowClear
              filterOption={filterOption}
            >
              {Object.values(Division).map((division) => (
                <Select.Option key={division} value={division}>
                  {displayEnumValue(division)}
                </Select.Option>
              ))}
            </Select>
          </InputFormItem>
          <InputFormItem
            form={form}
            label="Match type"
            name="matchType"
            rules={[{ required: true }]}
          >
            <Select
              disabled={submittingMatch}
              onChange={onMatchTypeChange}
              showSearch
              allowClear
              filterOption={filterOption}
            >
              {Object.values(MatchType).map((matchType) => (
                <Select.Option key={matchType} value={matchType}>
                  {displayEnumValue(matchType)}
                </Select.Option>
              ))}
            </Select>
          </InputFormItem>
          <InputFormItem
            form={form}
            label="Competitors"
            name="competitorIds"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              onChange={onCompetitorsChange}
              disabled={submittingMatch}
              showSearch
              filterOption={filterOption}
            >
              {competitors.map((competitor) => (
                <Select.Option key={competitor.id} value={competitor.id}>
                  {competitor.name}
                </Select.Option>
              ))}
            </Select>
          </InputFormItem>
          {currentCompetitors?.map((competitor) => (
            <React.Fragment key={competitor.id}>
              <InputFormItem
                key={competitor.id}
                form={form}
                label={`${competitor.name}'s Score`}
                name={competitor.id}
              >
                <InputNumber disabled={submittingMatch} />
              </InputFormItem>
              <InputFormItem
                form={form}
                label={`${competitor.name}'s Outcomes`}
                name={`${competitor.id}_outcomes`}
              >
                <Select
                  mode="multiple"
                  onChange={handleFormChange}
                  disabled={submittingMatch}
                  showSearch
                  filterOption={filterOption}
                >
                  {Object.values(Outcome)
                    .map((outcome) =>
                      outcome !== Outcome.MVP ||
                      currentMatchType === MatchType.FFA ? (
                        <Select.Option key={outcome} value={outcome}>
                          {displayEnumValue(outcome)}
                        </Select.Option>
                      ) : null
                    )
                    .filter((option) => option)}
                </Select>
              </InputFormItem>
            </React.Fragment>
          ))}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!canSubmit}
              loading={submittingMatch}
            >
              Submit Match
            </Button>
          </Form.Item>
        </Form>
        <Row>
          <Space direction="horizontal">
            {match ? (
              <Popconfirm
                title="You sure you want to delete this match?"
                onConfirm={deleteMatch}
              >
                <Button>Delete</Button>
              </Popconfirm>
            ) : null}
          </Space>
        </Row>
      </Drawer>
    </Row>
  );
};

export default Match;
