import React, { ReactElement } from 'react';
import { ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import Header from '../components/Header';
import Footer from '../components/Footer';
import antdConfig from '../utils/antdConfig';

interface iProps {}

const Page: React.FC<iProps> = ({ children }): ReactElement | null => {
  return (
    <ConfigProvider {...antdConfig}>
      <Layout className="page">
        <Header />
        <Layout>
          <Content className="page__main-content">{children}</Content>
        </Layout>
        <Footer />
      </Layout>
    </ConfigProvider>
  );
};

export default Page;
