import Model, { ModelData } from './Model';
import PlayerFactionMembership, {
  PlayerFactionMembershipData,
} from './PlayerFactionMembership';

export interface FactionRosterSpotData extends ModelData {
  rosterNumber: number;
  playerFactionMembership?: PlayerFactionMembershipData;
}

export default class FactionRosterSpot extends Model {
  readonly rosterNumber: number;
  readonly playerFactionMembership?: PlayerFactionMembership;

  constructor(data: FactionRosterSpotData) {
    super(data);
    this.rosterNumber = data.rosterNumber;
    this.playerFactionMembership =
      data.playerFactionMembership &&
      new PlayerFactionMembership(data.playerFactionMembership);
  }
}
