import React, { ReactElement, useCallback, useState } from 'react';
import { Button, Modal } from 'antd';

import Player from '../api/Player';
import MatchList from './MatchList';

interface iProps {
  player: Player;
}

const PlayerDetailButton: React.FC<iProps> = ({
  player,
}): ReactElement | null => {
  const [openedPlayer, setOpenedPlayer] = useState<Player>();

  const openPlayerModal = useCallback(() => {
    setOpenedPlayer(player);
  }, [player]);

  const closePlayerModal = useCallback(() => {
    setOpenedPlayer(undefined);
  }, []);

  return (
    <React.Fragment>
      <Modal
        visible={!!openedPlayer}
        title={player.name}
        onCancel={closePlayerModal}
        width="100%"
        footer={null}
      >
        {/* TODO: Include lifetime faction point total here */}
        <MatchList featuringPlayer={player} />
      </Modal>
      <Button
        className="player-name-button"
        type="link"
        onClick={openPlayerModal}
      >
        {player.name}
      </Button>
    </React.Fragment>
  );
};

export default PlayerDetailButton;
