import { useEffect } from 'react';

import {
  EventType,
  Event,
  EventMap,
  EVENT_CHANNELS_PREFIX,
} from '../shared/events';
import { Firebase } from '../utils/firebase';
import useFirebase from './useFirebase';

const useEventListener = <T extends EventType>(
  channel: string | undefined,
  event: T,
  listener: (eventData: EventMap[T]) => void
): null => {
  const firebase = useFirebase();
  useEffect(() => {
    if (channel) {
      const channelRef = dbRef(firebase, EVENT_CHANNELS_PREFIX, channel);
      channelRef
        .orderByChild('sentTs')
        .startAt(Date.now())
        .on('child_added', (snapshot) => {
          const eventData: Event = snapshot.val();
          if (isEventType(event, eventData)) {
            listener(eventData);
          }
        });

      return (): void => {
        channelRef.off();
      };
    }
  }, [channel, event, firebase, listener]);
  return null;
};

// Tricky to grab this return type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const dbRef = (firebase: Firebase, ...keyPaths: string[]) => {
  const database = firebase.database();

  return database.ref(keyPaths.join('/'));
};

const isEventType = <T extends EventType>(
  eventType: T,
  event: Event
): event is EventMap[T] => {
  return event.eventType === eventType;
};

export default useEventListener;
