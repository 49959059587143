import { Button, Card, Input, Popconfirm, Spin, Table } from 'antd';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import api from '../api';
import Draft from '../api/Draft';
import DraftPick from '../api/DraftPick';
import PlayerModel from '../api/Player';
import { handleError } from '../utils';
import PlayerDetailButton from './PlayerDetailButton';

interface iProps {
  draft: Draft;
  availableInLeagueId: string;
  draftPlayer: (
    pick: DraftPick,
    selectedPlayer: PlayerModel
  ) => Promise<DraftPick | void>;
  pick?: DraftPick;
}

const DraftPlayerList: React.FC<iProps> = ({
  draft,
  availableInLeagueId,
  draftPlayer,
  pick,
}): ReactElement | null => {
  const [players, setPlayers] = useState<PlayerModel[]>();
  const [
    currentlySubmittingPlayerId,
    setCurrentlySubmittingPlayerId,
  ] = useState<string>();
  const [search, setSearch] = useState('');

  useEffect(() => {
    api
      .getPlayers({ availableInLeagueId })
      .then((players) => {
        setPlayers(players);
      })
      .catch(handleError);
  }, [availableInLeagueId]);

  useEffect(() => {
    setPlayers((players) => {
      return players?.filter((player) =>
        draft.orderedDraftPicks.every(
          (pick) => pick.playerFactionMembership?.player.id !== player.id
        )
      );
    });
  }, [draft.orderedDraftPicks]);

  const getDraftPlayerFn = useCallback(
    (player: PlayerModel): (() => void) | undefined => {
      if (!pick) {
        return undefined;
      }

      return (): void => {
        setCurrentlySubmittingPlayerId(player.id);
        draftPlayer(pick, player).finally(() =>
          setCurrentlySubmittingPlayerId(undefined)
        );
      };
    },
    [draftPlayer, pick]
  );

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const normalizeTextForSearch = useCallback((text: string) => {
    return text.toLowerCase().replace(/\s/g, '');
  }, []);

  const filteredPlayers = useMemo(() => {
    return players?.filter((player) =>
      normalizeTextForSearch(player.name).includes(
        normalizeTextForSearch(search)
      )
    );
  }, [search, players, normalizeTextForSearch]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Player',
        dataIndex: 'name',
        render: (_text: string, player: PlayerModel): ReactElement => {
          return <PlayerDetailButton player={player} />;
        },
      },
      {
        title: '',
        key: 'action',
        width: 100,
        render: (_text: string, player: PlayerModel): ReactElement => {
          const handleDraftPlayer = getDraftPlayerFn(player);
          const disabled = !handleDraftPlayer || !!currentlySubmittingPlayerId;

          return (
            <Popconfirm
              title="Draft player? This can't be undone."
              onConfirm={handleDraftPlayer}
              disabled={disabled}
            >
              <Button
                type="primary"
                disabled={disabled}
                loading={currentlySubmittingPlayerId === player.id}
              >
                Draft
              </Button>
            </Popconfirm>
          );
        },
      },
    ];
  }, [getDraftPlayerFn, currentlySubmittingPlayerId]);

  if (!players) {
    return <Spin />;
  }

  return (
    <Card title="Players" className="player-list">
      <Input.Search
        className="player-list-search"
        placeholder="Search players"
        onChange={onSearch}
        onSearch={setSearch}
      />
      <div className="scroll-gradient">
        <Table
          showHeader={false}
          className="player-list__table"
          dataSource={filteredPlayers}
          columns={columns}
          rowKey="id"
          rowClassName="player-list__row"
          pagination={false}
          scroll={{ y: '59vh' }}
          locale={{ emptyText: 'No players found' }}
        />
      </div>
    </Card>
  );
};

export default DraftPlayerList;
