import Model, { ModelData } from './Model';
import Player, { PlayerData } from './Player';

export interface CompetitorData extends ModelData {
  name: string;
  players: PlayerData[];
}

export default class Competitor extends Model {
  readonly name: string;
  readonly players: Player[];

  constructor(data: CompetitorData) {
    super(data);
    this.name = data.name;
    this.players = data.players.map((player) => new Player(player));
  }
}
