import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE } from '..';

export const useRedirectPath = (): string => {
  const location = useLocation();

  return useMemo(() => {
    const path = new URLSearchParams(location.search).get('redirect');
    return path ? decodeURIComponent(path) : ROUTE.HOME;
  }, [location.search]);
};

export const useWithRedirect = (): ((route: string) => string) => {
  const location = useLocation();

  return useCallback(
    (route: string) => {
      return `${route}?redirect=${encodeURIComponent(
        location.pathname + location.search
      )}`;
    },
    [location]
  );
};
