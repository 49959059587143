import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Header as AntdHeader } from 'antd/lib/layout/layout';
import { Col, Row, Space } from 'antd';

import { ROUTE } from '../index';
import UserDropdownMenu, { iMenuItem } from './UserDropdownMenu';
import useFirebase from '../hooks/useFirebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import { useAppContext } from '../hooks/useAppContext';
import Logo from './Logo';
import { handleError } from '../utils';
import NotificationBell from './NotificationBell';

interface iProps {}

const Header: React.FC<iProps> = (): ReactElement | null => {
  const firebaseInstance = useFirebase();
  const firebaseUser = useFirebaseUser();
  const {
    unsetUser,
    state: { user },
  } = useAppContext();

  const menuItems: iMenuItem[] = useMemo(() => {
    const adminLink = user?.isAdmin
      ? {
          title: 'Admin Console',
          nestedItems: [
            {
              title: 'Create Players',
              to: ROUTE.ADMIN_CREATE_PLAYER,
            },
            {
              title: 'Create Competitors',
              to: ROUTE.ADMIN_CREATE_COMPETITOR,
            },
            {
              title: 'Manage Matches',
              to: ROUTE.ADMIN_MATCH,
            },
          ],
        }
      : null;
    return [
      ...(adminLink ? [adminLink] : []),
      {
        title: 'My Leagues',
        to: ROUTE.MY_LEAGUES,
      },
      {
        title: 'Matches',
        to: ROUTE.MATCHES,
      },
      {
        title: 'Player Rankings',
        to: ROUTE.PLAYER_RANKINGS,
      },
      {
        title: 'How does this work?',
        to: ROUTE.HOW_DOES_THIS_WORK,
      },
      {
        title: 'Log out',
        action: (): void => {
          unsetUser();
          firebaseInstance.auth().signOut().catch(handleError);
        },
      },
    ];
  }, [user, firebaseInstance, unsetUser]);

  return (
    <AntdHeader className="header">
      <Row justify="space-between">
        <Col>
          <h1>
            <Link to="/" className="header__home-link">
              <Logo />
            </Link>
          </h1>
        </Col>
        {firebaseUser ? (
          <Col>
            <Space size="large">
              <NotificationBell />
              <UserDropdownMenu userName={user?.username} items={menuItems} />
            </Space>
          </Col>
        ) : null}
      </Row>
    </AntdHeader>
  );
};

export default Header;
