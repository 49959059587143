import { Dayjs } from 'dayjs';

import dayjs from '../utils/dayjsInstance';

export interface ModelData {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export default class Model {
  readonly id: string;
  readonly createdAt: Dayjs;
  readonly updatedAt: Dayjs;

  constructor(data: ModelData) {
    this.id = data.id;
    this.createdAt = dayjs(data.createdAt);
    this.updatedAt = dayjs(data.updatedAt);
  }
}
