import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import { handleError } from '.';

const init = (): typeof firebase => {
  if (!process.env.REACT_APP_FIREBASE_CONFIG) {
    const error = new Error('Could not load configuration');
    handleError(error);
    throw error;
  }

  const firebaseConfig: object = JSON.parse(
    process.env.REACT_APP_FIREBASE_CONFIG
  );
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  return firebase;
};

const authUIConfig: firebaseui.auth.Config = {
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

const firebaseInstance = init();

export type Firebase = typeof firebase;

const firebaseUtil = { firebase: firebaseInstance, authUIConfig };
export default firebaseUtil;
