import React, { ReactElement, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Typography } from 'antd';

import firebaseUtil from '../utils/firebase';
import useFirebase from '../hooks/useFirebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import { useRedirectPath } from '../hooks/redirection';
import { handleError } from '../utils';

interface iProps {}

const Auth: React.FC<iProps> = (): ReactElement | null => {
  const firebaseInstance = useFirebase();
  const firebaseUser = useFirebaseUser();
  const redirectPath = useRedirectPath();

  const signInSuccess = useCallback(
    (result: firebase.auth.UserCredential): boolean => {
      const user: firebase.User | null = result.user;

      if (user && !user.emailVerified) {
        user
          .sendEmailVerification({
            url: window.origin + redirectPath,
          })
          .catch(handleError);
      }

      // Returning false tells firebase auth to do
      // nothing because we're handling it.
      return false;
    },
    [redirectPath]
  );

  if (firebaseUser && firebaseUser.emailVerified) {
    return <Redirect to={redirectPath} />;
  } else if (firebaseUser && !firebaseUser.emailVerified) {
    return (
      <Typography.Text>
        Please check your inbox at {firebaseUser.email} for your verification
        link to continue.
      </Typography.Text>
    );
  }

  return (
    <StyledFirebaseAuth
      uiConfig={{
        ...firebaseUtil.authUIConfig,
        callbacks: {
          signInSuccessWithAuthResult: signInSuccess,
        },
      }}
      firebaseAuth={firebaseInstance.auth()}
    />
  );
};

export default Auth;
