export const EVENT_CHANNELS_PREFIX = 'event_channels';

export interface Event {
  eventType: EventType;
  sentTs: number;
}

export enum EventType {
  DRAFT_PICKS_UPDATED = 'draft_picks_updated',
  USER_NOTIFICATIONS_UDPATED = 'user_notifications_udpated',
}

export interface DraftPicksUpdatedEvent extends Event {
  eventType: EventType.DRAFT_PICKS_UPDATED;
  updatedDraftPicks: any[];
}

export interface UserNotificationsUdpatedEvent extends Event {
  eventType: EventType.USER_NOTIFICATIONS_UDPATED;
}

export interface EventMap {
  [EventType.DRAFT_PICKS_UPDATED]: DraftPicksUpdatedEvent;
  [EventType.USER_NOTIFICATIONS_UDPATED]: UserNotificationsUdpatedEvent;
}
