import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useTabHashRouting = (
  defaultTabKey: string
): {
  activeKey: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
} => {
  const history = useHistory();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(
    location.hash.replace('#', '') || defaultTabKey
  );

  useEffect(() => {
    history.replace(`#${activeKey}`);
  }, [history, activeKey]);

  return { activeKey, onChange: setActiveKey };
};

export default useTabHashRouting;
