import React, { ReactElement, useCallback, useMemo } from 'react';
import { Col, Row, Space, Spin, Table, Typography } from 'antd';
import { Dayjs } from 'dayjs';

import League from '../api/League';
import Faction from '../api/Faction';
import api from '../api';
import { usePagination } from '../hooks/PaginationHooks';
import Paginated from '../api/Paginated';
import { handleError } from '../utils';
import Match from '../api/Match';
import { Outcome } from '../shared/constants';

interface iProps {
  league: League;
  faction: Faction;
}

const FactionMatches: React.FC<iProps> = ({
  league,
  faction,
}): ReactElement | null => {
  const { items: matches, paginationProps } = usePagination(
    useCallback(
      (page, pageSize): Promise<Paginated<Match> | void> => {
        return api
          .getFactionMatches(league.id, faction.id, {
            page,
            pageSize,
          })
          .catch(handleError);
      },
      [league.id, faction.id]
    )
  );

  const renderDate = useCallback((date: Dayjs) => {
    return date?.format('LL') || '--';
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Date',
        dataIndex: 'date',
        render: renderDate,
      },
      {
        title: 'Division',
        dataIndex: 'divisionName',
      },
      {
        title: 'Match Type',
        dataIndex: 'matchTypeName',
      },
      {
        title: 'Competitors',
        key: 'competitors',
        render: (_text: string, match: Match): ReactElement => {
          if (match.matchCompetitors.length <= 5) {
            return (
              <>
                {match.matchCompetitors.map((matchCompetitor) => (
                  <Row key={matchCompetitor.id}>
                    <Typography.Text>
                      {matchCompetitor.competitor.name}
                    </Typography.Text>
                  </Row>
                ))}
              </>
            );
          } else {
            return (
              <Typography.Text>
                {match.matchCompetitors.length} competitors
              </Typography.Text>
            );
          }
        },
      },
      {
        title: 'Winner',
        key: 'winner',
        render: (_text: string, match: Match): string => {
          const winner = match.matchCompetitors.find(
            (matchCompetitor) =>
              matchCompetitor.outcomes &&
              matchCompetitor.outcomes.includes(Outcome.WON)
          );

          if (winner) {
            return winner.competitor.name;
          } else {
            return '--';
          }
        },
      },
      {
        title: 'Points earned',
        dataIndex: 'pointsEarned',
      },
    ];
  }, [renderDate]);

  if (!matches) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" className="space-wrapper">
          <Table
            dataSource={matches}
            columns={columns}
            rowKey="id"
            locale={{ emptyText: 'No matches yet' }}
            pagination={false}
            scroll={{ x: 613 }}
          />
          {paginationProps.loadingPage ? (
            <Row justify="center">
              <Spin />
            </Row>
          ) : null}
          <Row justify="center">{paginationProps.loadMoreElement}</Row>
        </Space>
      </Col>
    </Row>
  );
};

export default FactionMatches;
