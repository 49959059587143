import React, { ReactElement } from 'react';
import { Col, Progress, Row, Typography } from 'antd';

import League from '../api/League';

interface iProps {
  league: League;
}

const LeagueFactionCapacity: React.FC<iProps> = ({
  league,
}): ReactElement | null => {
  return (
    <Row>
      <Col span={20}>
        <Progress percent={league.factionCapacityPercentage} showInfo={false} />
      </Col>
      <Col span={4}>
        <Typography.Text className="league-row__faction-capacity-text">
          {league.factionCapacityFraction}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default LeagueFactionCapacity;
