import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  List,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { Link, useParams } from 'react-router-dom';

import { useAppContext } from '../hooks/useAppContext';
import api from '../api';
import TradeModel from '../api/Trade';
import { handleError } from '../utils';
import PlayerFactionMembership from '../api/PlayerFactionMembership';
import { ROUTE, routePath } from '..';
import BackLink from '../components/BackLink';
import League from '../api/League';
import PlayerDetailButton from '../components/PlayerDetailButton';

interface Params {
  leagueId: string;
  tradeId: string;
}

interface iProps {}

const Trade: React.FC<iProps> = (): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const { leagueId, tradeId } = useParams<Params>();
  const [trade, setTrade] = useState<TradeModel>();
  const [league, setLeague] = useState<League>();
  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  useEffect(() => {
    api
      .getTrade(leagueId, tradeId)
      .then((trade) => {
        setTrade(trade);
      })
      .catch(handleError);
  }, [leagueId, tradeId]);

  useEffect(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  const updateTrade = useCallback(
    (approved: boolean) => {
      const setLoading = approved ? setApproving : setRejecting;

      setLoading(true);
      api
        .updateTrade({ leagueId, tradeId, approved })
        .then((trade) => setTrade(trade))
        .catch(handleError)
        .finally(() => setLoading(false));
    },
    [leagueId, tradeId]
  );

  const acceptTrade = useCallback(() => {
    updateTrade(true);
  }, [updateTrade]);

  const rejectTrade = useCallback(() => {
    updateTrade(false);
  }, [updateTrade]);

  const renderMembersipConfirmationItem = useCallback(
    (membership: PlayerFactionMembership) => (
      <List.Item>
        <Typography.Text>
          <PlayerDetailButton player={membership.player} />
        </Typography.Text>
      </List.Item>
    ),
    []
  );

  const usersYetToApprove = useMemo(() => {
    if (!trade) {
      return undefined;
    }

    return trade.tradePackages
      .map((tradePackage) => tradePackage.fromFaction.manager)
      .filter((user) =>
        trade.tradeApprovals.every(
          (tradeApproval) => tradeApproval.user.id !== user.id
        )
      );
  }, [trade]);

  const tradeInvolvesCurrentUser = useMemo(() => {
    return trade?.tradePackages.some(
      (tradePackage) => tradePackage.fromFaction.manager.id === user?.id
    );
  }, [user, trade]);

  const tradeRejectors = useMemo(() => {
    return trade?.tradeApprovals
      .filter((tradeApproval) => !tradeApproval.approved)
      .map((tradeApproval) => tradeApproval.user);
  }, [trade]);

  const currentUserTradeApproval = useMemo(() => {
    return trade?.tradeApprovals.find(
      (tradeApproval) => tradeApproval.user.id === user?.id
    );
  }, [trade, user]);

  if (!trade || !user || !league) {
    return <Spin />;
  }

  return (
    <Row className="trade" justify="center">
      <Col span={24} lg={18} xl={16}>
        <Card
          title={
            <Space>
              <BackLink />
              <span>Trade {!trade.hasBeenExecuted ? 'offer' : null}</span>
            </Space>
          }
          extra={
            `League: ${league.name}` +
            (trade.hasBeenExecuted
              ? `, Executed on ${trade.executionTime.format('LLL')}`
              : trade.cancelled
              ? ''
              : trade.expired
              ? `, Expired on ${trade.expiryTime.format('LLL')}`
              : `, Expires on ${trade.expiryTime.format('LLL')}`)
          }
        >
          <Space direction="vertical" size="large" className="space-wrapper">
            <Row justify="center">
              <Typography.Text>
                Note: Player's are only eligible to accumulate points for a
                faction at 12:00AM PST the day after a trade has been executed.
              </Typography.Text>
            </Row>
            <Row justify="space-around" gutter={16}>
              {trade.tradePackages.map((tradePackage) => (
                <Col span={12} key={tradePackage.id}>
                  <List
                    header={
                      <React.Fragment>
                        <Link
                          to={routePath(ROUTE.FACTION, {
                            leagueId,
                            factionId: tradePackage.fromFaction.id,
                          })}
                        >
                          {user.id === tradePackage.fromFaction.manager.id
                            ? 'Your faction'
                            : tradePackage.fromFaction.name}
                        </Link>{' '}
                        {!trade.hasBeenExecuted ? 'would send' : 'sent'}
                      </React.Fragment>
                    }
                    bordered
                    size="small"
                    dataSource={tradePackage.memberships}
                    renderItem={renderMembersipConfirmationItem}
                    pagination={false}
                  />
                </Col>
              ))}
            </Row>
            {trade.expired ? (
              <Row justify="center" align="middle">
                Trade offer has expired.
              </Row>
            ) : trade.cancelled ? (
              <Row justify="center" align="middle">
                Trade offer has been cancelled.
              </Row>
            ) : !currentUserTradeApproval && tradeInvolvesCurrentUser ? (
              <Row justify="center" align="middle">
                <Space>
                  <Popconfirm
                    title="Accept trade? This can't be undone."
                    onConfirm={acceptTrade}
                  >
                    <Button
                      type="primary"
                      loading={approving}
                      disabled={rejecting}
                    >
                      Accept trade
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Reject trade? This can't be undone."
                    onConfirm={rejectTrade}
                  >
                    <Button loading={rejecting} disabled={approving}>
                      Reject trade
                    </Button>
                  </Popconfirm>
                </Space>
              </Row>
            ) : currentUserTradeApproval &&
              !currentUserTradeApproval.approved ? (
              <Row justify="center" align="middle">
                You've rejected this trade offer
              </Row>
            ) : tradeRejectors?.length ? (
              <Row justify="center" align="middle">
                Trade rejected by{' '}
                {tradeRejectors?.map((u) => u.username).join(',')}
              </Row>
            ) : usersYetToApprove?.length && tradeInvolvesCurrentUser ? (
              <Row justify="space-between" align="middle">
                <Col>
                  Waiting for approval from{' '}
                  {usersYetToApprove.map((u) => u.username).join(',')}
                </Col>
                <Col>
                  <Popconfirm
                    title="Cancel trade? This can't be undone."
                    onConfirm={rejectTrade}
                  >
                    <Button type="primary" loading={rejecting}>
                      Cancel trade offer
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            ) : trade.hasBeenExecuted ? (
              <Row justify="center" align="middle">
                Trade has been executed
              </Row>
            ) : null}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default Trade;
