import { Dayjs } from 'dayjs';
import { User } from '@sentry/react';

import Model, { ModelData } from './Model';
import TradeApproval, { TradeApprovalData } from './TradeApproval';
import TradePackage, { TradePackageData } from './TradePackage';
import dayjs from '../utils/dayjsInstance';

export interface TradeData extends ModelData {
  tradeApprovals: TradeApprovalData[];
  tradePackages: TradePackageData[];
  hasBeenExecuted: boolean;
  executionTime: string;
  expiryTime: string;
  cancelled: boolean;
}

export default class Trade extends Model {
  readonly tradeApprovals: TradeApproval[];
  readonly tradePackages: TradePackage[];
  readonly hasBeenExecuted: boolean;
  readonly executionTime: Dayjs;
  readonly expiryTime: Dayjs;
  readonly cancelled: boolean;

  constructor(data: TradeData) {
    super(data);
    this.tradeApprovals = data.tradeApprovals.map(
      (tradeApproval) => new TradeApproval(tradeApproval)
    );
    this.tradePackages = data.tradePackages.map(
      (tradePackage) => new TradePackage(tradePackage)
    );
    this.hasBeenExecuted = data.hasBeenExecuted;
    this.executionTime = dayjs(data.executionTime);
    this.expiryTime = dayjs(data.expiryTime);
    this.cancelled = data.cancelled;
  }

  get status(): string {
    if (this.expired) {
      return 'Expired';
    } else if (this.cancelled) {
      return 'Cancelled';
    } else if (this.hasBeenExecuted) {
      return 'Executed';
    } else if (this.rejected) {
      return 'Rejected';
    } else {
      return 'Pending approval';
    }
  }

  get expired(): boolean {
    return this.expiryTime.isBefore(dayjs()) && !this.hasBeenExecuted;
  }

  get rejected(): boolean {
    return this.tradeApprovals.some((tradeApproval) => !tradeApproval.approved);
  }

  get offeredBy(): User | undefined {
    return this.tradeApprovals.find(
      (tradeApproval) => tradeApproval.fromInitiator
    )?.user;
  }

  get offeredTo(): User | undefined {
    const offeredBy = this.offeredBy;
    return this.tradePackages.find(
      (tradePackage) => tradePackage.fromFaction.manager.id === offeredBy?.id
    )?.toFaction.manager;
  }
}
