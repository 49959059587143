import React, { ReactElement, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ROUTE, routePath } from '..';
import api from '../api';
import useFormWithDisableableSubmit from '../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../components/InputFormItem';
import { handleError } from '../utils';

type FormData = {
  leagueName: string;
  maxFactionCount: number;
  rosterSpotsPerFaction: number;
  hidden: boolean;
};

const CreateLeague: React.FC = (): ReactElement | null => {
  const history = useHistory();
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({
    maxFactionCount: 8,
    rosterSpotsPerFaction: 8,
  });
  const [creatingLeague, setCreatingLeague] = useState(false);

  const handleCreateLeague = useCallback(
    (values: FormData) => {
      const {
        leagueName,
        maxFactionCount,
        rosterSpotsPerFaction,
        hidden,
      } = values;

      setCreatingLeague(true);
      api
        .createLeague({
          name: leagueName,
          maxFactionCount,
          rosterSpotsPerFaction,
          hidden,
        })
        .then((leaugue) => {
          history.push(routePath(ROUTE.LEAGUE, { leagueId: leaugue.id }));
        })
        .catch((error) => {
          setCreatingLeague(false);
          handleError(error);
        });
    },
    [history]
  );

  return (
    <Row justify="center" className="page__vertically-centered">
      <Col xs={24} md={10}>
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateLeague}
            onChange={handleFormChange}
          >
            <InputFormItem
              form={form}
              label="League name"
              name="leagueName"
              rules={[{ required: true }]}
            >
              <Input disabled={creatingLeague} />
            </InputFormItem>
            <InputFormItem
              form={form}
              label="Max number of factions"
              name="maxFactionCount"
              rules={[{ required: true }, { type: 'number', min: 2, max: 8 }]}
            >
              <InputNumber disabled={creatingLeague} />
            </InputFormItem>
            <InputFormItem
              form={form}
              label="Number of players per faction"
              name="rosterSpotsPerFaction"
              rules={[{ required: true }, { type: 'number', min: 2, max: 12 }]}
            >
              <InputNumber disabled={creatingLeague} />
            </InputFormItem>
            <InputFormItem
              form={form}
              label={
                <div>
                  Hide league from main discover page{' '}
                  <Tooltip title="Managers will need to be sent the link to your league directly">
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              }
              name="hidden"
              initialValue={false}
            >
              <Switch disabled={creatingLeague} />
            </InputFormItem>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!canSubmit}
                loading={creatingLeague}
              >
                Create League
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateLeague;
