import sortBy from 'lodash.sortby';

import Faction, { FactionData } from './Faction';
import Model, { ModelData } from './Model';
import Season, { SeasonData } from './Season';
import User, { UserData } from './User';

export interface LeagueData extends ModelData {
  name: string;
  maxFactionCount: number;
  rosterSpotsPerFaction: number;
  hidden: boolean;
  season: SeasonData;
  commissioner: UserData;
  factions: FactionData[];
  canBeJoined: boolean;
  discordLink?: string;
}

export default class League extends Model {
  readonly name: string;
  readonly maxFactionCount: number;
  readonly rosterSpotsPerFaction: number;
  readonly hidden: boolean;
  readonly season: Season;
  readonly commissioner: User;
  readonly factions: Faction[];
  readonly canBeJoined: boolean;
  readonly discordLink?: string;

  constructor(data: LeagueData) {
    super(data);
    this.name = data.name;
    this.maxFactionCount = data.maxFactionCount;
    this.rosterSpotsPerFaction = data.rosterSpotsPerFaction;
    this.hidden = data.hidden;
    this.season = new Season(data.season);
    this.commissioner = new User(data.commissioner);
    this.factions = sortBy(
      data.factions.map((faction) => new Faction(faction)),
      (faction) => faction.pointTotal
    ).reverse();
    this.canBeJoined = data.canBeJoined;
    this.discordLink = data.discordLink;
  }

  get factionCapacityFraction(): string {
    return `${this.factions.length} / ${this.maxFactionCount}`;
  }

  get factionCapacityPercentage(): number {
    return 100 * (this.factions.length / this.maxFactionCount);
  }

  canUserJoin(user: User): boolean {
    return (
      this.canBeJoined &&
      this.factions.every((faction) => faction.manager.id !== user.id)
    );
  }
}
