export interface PendingTradeNotificationData {
  id: string;
  type: 'trade';
  description: string;
  tradeId: string;
  leagueId: string;
}

export default class PendingTradeNotification {
  readonly id: string;
  readonly type: 'trade';
  readonly description: string;
  readonly tradeId: string;
  readonly leagueId: string;

  constructor(data: PendingTradeNotificationData) {
    this.id = data.tradeId;
    this.type = data.type;
    this.description = data.description;
    this.tradeId = data.tradeId;
    this.leagueId = data.leagueId;
  }
}

export type UserNotificationData = PendingTradeNotificationData;
export type UserNotification = PendingTradeNotification;
