export const MIN_MINUTES_PER_DRAFT_PICK = 1;
export const MINUTES_PER_DRAFT_PICK_DIVISIBLE_BY = 0.25;
export const MAX_MINUTES_PER_DRAFT_PICK = 5;

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export enum Division {
  Singles = 'singles',
  Teams = 'teams',
  Innergeekdom = 'innergeekdom',
  StarWars = 'star_wars',
}

export enum MatchType {
  REGULAR_SEASON = 'regular_season',
  NUMBER_ONE_CONTENDER = 'number_one_contender',
  TITLE = 'title',
  TOURNAMENT = 'tournament',
  TOURNAMENT_FINALS = 'tournament_finals',
  TRIPLE_THREAT = 'triple_threat',
  TITLE_TRIPLE_THREAT = 'title_triple_threat',
  FATAL_FOURWAY_TITLE = 'fatal_fourway_title',
  FATAL_FIVEWAY_TITLE = 'fatal_fiveway_title',
  FFA = 'free_for_all',
  SPECATULAR_TITLE = 'specatular_title',
  // SPECTACULAR_NON_TITLE = 'spectacular_non_title',
}

export const titleMatchTypes = [
  MatchType.TITLE,
  MatchType.TITLE_TRIPLE_THREAT,
  MatchType.FATAL_FOURWAY_TITLE,
  MatchType.FATAL_FIVEWAY_TITLE,
  MatchType.SPECATULAR_TITLE,
];

export enum Outcome {
  WON = 'won',
  MVP = 'mvp',
  LOST = 'lost',
  TECHNICALLY_KNOCKED_OUT = 'technically_knocked_out',
  KNOCKED_OUT = 'knocked_out',
  TITLE_DEFENSE = 'title_defense',
}

export enum MembershipCause {
  DRAFT_PICK = 'draft_pick',
  PICKUP = 'pickup',
  TRADE = 'trade',
}
