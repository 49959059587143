import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';

import api from '../api';
import FactionModel from '../api/Faction';
import { handleError } from '../utils';
import { ROUTE, routePath } from '..';
import FactionRosterSpot from '../api/FactionRosterSpot';
import { useAppContext } from '../hooks/useAppContext';
import BackLink from '../components/BackLink';
import League from '../api/League';
import Draft from '../api/Draft';
import Trades from '../components/Trades';
import useTabHashRouting from '../hooks/useActiveTabKey';
import FactionMembershipHistory from '../components/FactionMembershipHistory';
import FactionMatches from '../components/FactionMatches';
import PlayerDetailButton from '../components/PlayerDetailButton';

interface Params {
  leagueId: string;
  factionId: string;
}

const Faction: React.FC = (): ReactElement | null => {
  const tabProps = useTabHashRouting('overview');
  const {
    state: { user },
  } = useAppContext();
  const { leagueId, factionId } = useParams<Params>();
  const [faction, setFaction] = useState<FactionModel>();
  const isYourFaction = faction?.manager.id === user?.id;
  const [league, setLeague] = useState<League>();
  const [draft, setDraft] = useState<Draft | null>();

  const refreshFaction = useCallback(() => {
    api
      .getFaction(leagueId, factionId)
      .then((faction) => {
        setFaction(faction);
      })
      .catch(handleError);
  }, [leagueId, factionId]);

  useEffect(() => {
    refreshFaction();
  }, [refreshFaction]);

  useEffect(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  useEffect(() => {
    api
      .getDraft(leagueId)
      .then((draft) => {
        setDraft(draft);
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError && error.response?.status === 404) {
          setDraft(null);
        } else {
          handleError(error);
        }
      });
  }, [leagueId]);

  const areYouALeagueMember = useMemo(() => {
    return league?.factions.some((faction) => faction.manager.id === user?.id);
  }, [league?.factions, user?.id]);

  const getDropPlayerFn = useCallback(
    (rosterSpot: FactionRosterSpot): (() => void) | undefined => {
      const playerFactionMembership = rosterSpot.playerFactionMembership;
      if (!playerFactionMembership) {
        return undefined;
      }

      return (): void => {
        api
          .dropPlayer({
            leagueId,
            factionId,
            playerFactionMembershipId: playerFactionMembership.id,
          })
          .then(refreshFaction)
          .catch(handleError);
      };
    },
    [leagueId, factionId, refreshFaction]
  );

  const columns: ColumnsType<FactionRosterSpot> = useMemo(() => {
    const actionColumns =
      isYourFaction && draft?.hasEnded && !league?.season.isEnded
        ? [
            {
              title: '',
              key: 'action',
              render: (
                _text: string,
                rosterSpot: FactionRosterSpot
              ): ReactElement | null => {
                const dropPlayer = getDropPlayerFn(rosterSpot);
                if (!dropPlayer) {
                  return (
                    <Link
                      className="ant-btn ant-btn-primary"
                      to={routePath(ROUTE.LEAGUE_PLAYERS, { leagueId })}
                    >
                      Add
                    </Link>
                  );
                }

                return (
                  <Popconfirm
                    title="Drop player? This can't be undone."
                    onConfirm={dropPlayer}
                  >
                    <Button>Drop</Button>
                  </Popconfirm>
                );
              },
            },
          ]
        : [];

    return [
      {
        title: 'Roster spot',
        dataIndex: 'rosterNumber',
      },
      {
        title: 'Player',
        dataIndex: 'id', // Not going to display, just for uniqueness
        render: (_text: string, rosterSpot: FactionRosterSpot): ReactNode => {
          if (!rosterSpot.playerFactionMembership) {
            return '--';
          } else {
            return (
              <PlayerDetailButton
                player={rosterSpot.playerFactionMembership.player}
              />
            );
          }
        },
      },
      ...actionColumns,
    ];
  }, [
    isYourFaction,
    draft?.hasEnded,
    league?.season.isEnded,
    getDropPlayerFn,
    leagueId,
  ]);

  if (!faction || !user || !league) {
    return <Spin />;
  }

  return (
    <Row className="faction" justify="center">
      <Col xs={24} md={16}>
        <Card
          className="tabbed-card"
          title={
            <Space>
              <BackLink to={routePath(ROUTE.LEAGUE, { leagueId })} />
              <span>
                {faction.name} (
                {isYourFaction ? 'Your' : `${faction.manager.username}'s`}{' '}
                faction)
              </span>
            </Space>
          }
          extra={`Points: ${faction.pointsDisplay}`}
        >
          <Tabs {...tabProps}>
            <Tabs.TabPane key="overview" tab="Overview">
              <Space direction="vertical" className="space-wrapper">
                <Space
                  direction="horizontal"
                  className="space-wrapper faction__overview-header"
                >
                  <Descriptions>
                    <Descriptions.Item label="Manager">
                      {isYourFaction ? 'You' : faction.manager.username}
                    </Descriptions.Item>
                  </Descriptions>
                  {!isYourFaction &&
                  areYouALeagueMember &&
                  !league.season.isEnded ? (
                    <Link
                      className="ant-btn ant-btn-primary"
                      to={routePath(ROUTE.OFFER_TRADE, {
                        leagueId,
                        factionId,
                      })}
                    >
                      Offer Trade
                    </Link>
                  ) : null}
                </Space>
                <Table
                  dataSource={faction.factionRosterSpots}
                  columns={columns}
                  rowKey="id"
                  pagination={false}
                />
              </Space>
            </Tabs.TabPane>
            <Tabs.TabPane key="matches" tab="Matches">
              <FactionMatches league={league} faction={faction} />
            </Tabs.TabPane>
            <Tabs.TabPane key="trades" tab="Trades">
              <Trades league={league} faction={faction} />
            </Tabs.TabPane>
            <Tabs.TabPane key="history" tab="Membership History">
              <FactionMembershipHistory league={league} faction={faction} />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  );
};

export default Faction;
