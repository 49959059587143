import { Card, Col, Row, Space, Typography } from 'antd';
import React, { ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ROUTE } from '..';
import api from '../api';
import League from '../api/League';
import Paginated from '../api/Paginated';
import DiscordLink from '../components/DiscordLink';
import LeagueList from '../components/LeagueList';
import { usePagination } from '../hooks/PaginationHooks';
import { handleError } from '../utils';

const DiscoverLeagues: React.FC = (): ReactElement | null => {
  const { items: leagues, paginationProps } = usePagination(
    useCallback((page, pageSize): Promise<Paginated<League> | void> => {
      return api
        .getLeagues(api.CURRENT_SEASON, undefined, { page, pageSize })
        .catch(handleError);
    }, [])
  );

  return (
    <Row justify="center" className="discover-leagues">
      <Col xs={24} md={16} lg={10}>
        <Typography.Title level={1}>Open Leagues</Typography.Title>
        <Card className="discover-leagues__cta-card">
          <Space align="center">
            <Typography.Text strong>
              Make sure to join the Discord if you join or create a league!
            </Typography.Text>{' '}
            <DiscordLink />
          </Space>
        </Card>
        <LeagueList
          header={
            <Card className="discover-leagues__cta-card">
              <Space align="center">
                <Typography.Text strong>
                  Want to be a commisioner?
                </Typography.Text>{' '}
                <Link
                  className="ant-btn ant-btn-primary"
                  to={ROUTE.CREATE_LEAGUE}
                >
                  Create your own league
                </Link>
              </Space>
            </Card>
          }
          leagues={leagues}
          paginationProps={paginationProps}
        />
      </Col>
    </Row>
  );
};

export default DiscoverLeagues;
