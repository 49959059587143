import { ConfigProviderProps } from 'antd/lib/config-provider';

const antdConfig: ConfigProviderProps = {
  form: {
    validateMessages: {
      // These templates of template messages are not a mistake, turn off eslint
      // rule.
      /* eslint-disable no-template-curly-in-string */
      required: "'${label}' is required",
      /* eslint-enable no-template-curly-in-string */
    },
  },
};

export default antdConfig;
