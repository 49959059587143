import Faction, { FactionData } from './Faction';
import Model, { ModelData } from './Model';
import PlayerFactionMembership, {
  PlayerFactionMembershipData,
} from './PlayerFactionMembership';

export interface TradePackageData extends ModelData {
  fromFaction: FactionData;
  toFaction: FactionData;
  memberships: PlayerFactionMembershipData[];
}

export default class TradePackage extends Model {
  readonly fromFaction: Faction;
  readonly toFaction: Faction;
  readonly memberships: PlayerFactionMembership[];

  constructor(data: TradePackageData) {
    super(data);
    this.fromFaction = new Faction(data.fromFaction);
    this.toFaction = new Faction(data.toFaction);
    this.memberships = data.memberships.map(
      (membership) => new PlayerFactionMembership(membership)
    );
  }
}
