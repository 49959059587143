import React, { ReactElement } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

import Auth from '../components/Auth';
import { ROUTE } from '..';

interface iProps {}

const SignIn: React.FC<iProps> = (): ReactElement | null => {
  return (
    <Row justify="center" className="page__vertically-centered sign-in">
      <Col>
        <Space direction="vertical">
          <div className="center-text">
            <Typography.Title level={2}>
              Sign in to get started!
            </Typography.Title>
            <Typography.Text>
              (Or <Link to={ROUTE.HOW_DOES_THIS_WORK}>click here</Link> to learn
              about MOTY first.)
            </Typography.Text>
          </div>
          <Auth />
        </Space>
      </Col>
    </Row>
  );
};

export default SignIn;
