export interface PaginatedData<iModelData> {
  readonly items: iModelData[];
  readonly meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  readonly links?: {
    first?: string;
    previous?: string;
    next?: string;
    last?: string;
  };
}

export default class Paginated<iModel, iModelData = any> {
  readonly items: iModel[];
  readonly meta: {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  readonly links?: {
    first?: string;
    previous?: string;
    next?: string;
    last?: string;
  };

  constructor(
    data: PaginatedData<iModelData>,
    constructor: new (data: iModelData) => iModel
  ) {
    this.items = data.items.map((i) => new constructor(i));
    this.meta = data.meta;
    this.links = data.links;
  }
}
