import React, { ReactElement } from 'react';

import DiscordLogo from './DiscordLogo';

interface iProps {
  link?: string;
}

const DiscordLink: React.FC<iProps> = ({ link }): ReactElement | null => {
  return (
    <a
      href={link || 'https://discord.gg/qqUPqr883K'}
      target="_blank"
      rel="noreferrer"
      className="ant-btn ant-btn-primary discord-link"
    >
      Join on <DiscordLogo />
    </a>
  );
};

export default DiscordLink;
