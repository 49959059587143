import React, { ReactElement } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { ROUTE } from '..';
import S8_POINT_SYSTEM_PT1 from '../assets/schmoedown-point-system.png';
import S8_POINT_SYSTEM_PT2 from '../assets/schmoedown-point-system-pt2.png';

interface iProps {}

const HowDoesThisWork: React.FC<iProps> = (): ReactElement | null => {
  return (
    <Row justify="center" className="how-does-this-work">
      <Col span={24} sm={20}>
        <Card
          title={
            <Typography.Title level={1}>How does this work?</Typography.Title>
          }
        >
          <Typography.Text>
            MOTY is a fantasy Schmoedown application. Anyone can create their
            own league, or join someone else's league and run a draft to build
            factions. As matches are released throughout the season each
            faction's points total will be automatically kept up to date
            according to the official Schmoedown point system.
          </Typography.Text>
          <Typography.Title level={2}>
            What does drafting look like?
          </Typography.Title>
          <Typography.Text>
            Keep reading for details, but here's a quick demo of the drafting
            interface:
          </Typography.Text>
          <div className="how-does-this-work__iframe-container">
            <iframe
              title="Draft Demo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Ggb2WoGZdyU"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <Typography.Title level={2}>How do i get started?</Typography.Title>
          <Typography.Text>
            Once a league is <Link to={ROUTE.HOME}>created</Link>, anyone will
            be able to join from the home page (or you can just send a direct
            link to a league page). Each league can have up to 8 factions (just
            like the real Schmoedown!). Each league gets its own automatically
            created channel on the MOTY discord server to be used for
            coordination and in-league communication. Once all your league's
            managers have created factions, you can set a date & time for your
            league's draft. When your draft time arrives head over to your
            league page and click View draft. A random{' '}
            <a target="__blank" href="https://slangit.com/meaning/snake_draft">
              snake draft
            </a>{' '}
            order will have been generated for you. Each manager will have a
            certain amount of time (set by the league commissioner) to choose a
            player when their pick times arrive. If you don't make a pick within
            that period of time, a random player will be autopicked for you. By
            the end of the draft, each manager will have 12 players on their
            roster (just like the real Schmoedown!).
          </Typography.Text>

          <Typography.Title level={2}>
            Can I change my roster after my draft?
          </Typography.Title>
          <Typography.Text>
            Yeup! Once your league's draft is completed you can go to your
            league's page, view your faction, and drop any of the players on
            your roster. Dropped players will immediately move into your
            league's free agency pool. Whenever you've got an open spot on your
            roster you can add any undrafted/free agent to your roster. If
            you're familiar with other fantasy sports, you may be wondering
            about waiver wires. At this time MOTY does not use a waiver wire,
            any free agent/undrafted player can be picked up immediately by any
            faction that has the roster spots once a league's draft has been
            completed.
          </Typography.Text>
          <br></br>
          <Typography.Text>
            <strong>Note:</strong> Your faction will only accumulate points for
            days in which a player is on your faction at the beginning of the
            day. That is, a player must be on your faction at{' '}
            <strong>12:00AM PST</strong> for points earned (or lost) by them on
            that day to be accumulated to your faction.
          </Typography.Text>
          <Typography.Title level={3}>What about trades?</Typography.Title>
          <Typography.Text>
            Trades are available too! Here's a quick demo:
          </Typography.Text>
          <div className="how-does-this-work__iframe-container">
            <iframe
              title="Trade Demo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/NL8LQK83Oy0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <Typography.Title level={2}>How do points work?</Typography.Title>
          <Typography.Text>
            Points will be accumulated in accordance with the official
            Schmoedown points system, with a couple of modifications listed
            below:
          </Typography.Text>
          <img src={S8_POINT_SYSTEM_PT1} alt="Schmoedown point system part 1" />
          <br></br>
          <br></br>
          <img src={S8_POINT_SYSTEM_PT2} alt="Schmoedown point system part 2" />
          <br></br>
          <br></br>
          <Typography.Text>
            - There will be no differences for in-faction matches. TKO's and
            KO's will result in the same point accumulations regardless of what
            faction a player is on in the real schmoedown league or in your MOTY
            league.
          </Typography.Text>
          <br></br>
          <Typography.Text>
            - Team match points will be split evenly between the factions that
            own each player on a given team.
          </Typography.Text>
          <br></br>
          <br></br>
          <Typography.Title level={2}>
            When are match results/updated rankings posted?
          </Typography.Title>
          <Typography.Text>
            In general we will try to upload match results on the same night
            they are released. This also applies to PPV live events. So be
            warned that if you haven't seen a match yet, you may be spoiled if
            you visit the site.
          </Typography.Text>
          <br></br>
          <br></br>
          <Typography.Title level={2}>
            Can i add FCL/undrafted players to my roster?
          </Typography.Title>
          <Typography.Text>
            Yeup you can! However you'll only accumulate points for official
            schmoedown matches, so picking up one of these players is a gamble
            you take.
          </Typography.Text>
          <br></br>
          <br></br>
          <Typography.Title level={2}>Have more questions?</Typography.Title>
          <Typography.Text>
            Join the{' '}
            <a target="__blank" href="https://discord.gg/qqUPqr883K">
              MOTY discord
            </a>{' '}
            and send a message to @TheRealJKJones!
          </Typography.Text>
          <br></br>
          <br></br>
          <Typography.Title level={4}>Legal:</Typography.Title>
          <Typography.Text>
            Notification Sounds from{' '}
            <a target="__blank" href="https://notificationsounds.com/">
              Notification Sounds
            </a>
            .
          </Typography.Text>
        </Card>
      </Col>
    </Row>
  );
};

export default HowDoesThisWork;
