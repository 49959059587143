import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useAppContext } from '../hooks/useAppContext';
import api from '../api';
import { handleError, makeNotificationSound } from '../utils';
import { UserNotification } from '../api/UserNotification';
import { ROUTE, routePath } from '..';
import useEventListener from '../hooks/useEventListener';
import { EventType } from '../shared/events';

interface iProps {}

const NotificationBell: React.FC<iProps> = (): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const [userNotifications, setUserNotifications] = useState<
    UserNotification[]
  >();
  const previousNotificationCount = useRef<number | null>(null);

  const refreshNotifications = useCallback(() => {
    if (user) {
      api
        .getNotificationsForUser(user.id)
        .then((notifications) => setUserNotifications(notifications))
        .catch(handleError);
    }
  }, [user]);

  useEffect(() => {
    refreshNotifications();
  }, [refreshNotifications]);

  useEffect(() => {
    if (
      userNotifications &&
      previousNotificationCount?.current !== null &&
      userNotifications.length > previousNotificationCount.current
    ) {
      makeNotificationSound();
    }
    previousNotificationCount.current = userNotifications?.length ?? null;
  }, [userNotifications]);

  useEventListener(
    user?.id,
    EventType.USER_NOTIFICATIONS_UDPATED,
    refreshNotifications
  );

  const renderNotification = useCallback((notification: UserNotification) => {
    switch (notification.type) {
      case 'trade':
        return (
          <Link
            to={routePath(ROUTE.TRADE, {
              leagueId: notification.leagueId,
              tradeId: notification.tradeId,
            })}
          >
            {notification.description}
          </Link>
        );
    }
  }, []);

  if (!user || !userNotifications) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      placement="bottomRight"
      overlay={
        <Menu className="notification-bell__menu">
          {userNotifications.map((userNotification) => (
            <Menu.Item key={userNotification.id}>
              {renderNotification(userNotification)}
            </Menu.Item>
          ))}
          {userNotifications.length === 0 ? (
            <Menu.Item>No Notifications</Menu.Item>
          ) : null}
        </Menu>
      }
    >
      <Badge count={userNotifications.length}>
        <Button className="notification-bell" icon={<BellOutlined />} />
      </Badge>
    </Dropdown>
  );
};

export default NotificationBell;
