import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { setupSentry } from './utils';
import { AppContextProvider } from './hooks/useAppContext';
import Page from './components/Page';
import AuthGuard from './components/AuthGuard';
import SignIn from './pages/SignIn';
import CreateAccount from './pages/CreateAccount';
import DiscoverLeagues from './pages/DiscoverLeagues';
import League from './pages/League';
import CreateFaction from './pages/CreateFaction';
import Faction from './pages/Faction';
import CreateLeague from './pages/CreateLeague';
import SetDraft from './pages/SetDraft';
import Draft from './pages/Draft';
import MyLeagues from './pages/MyLeagues';
import { Role } from './shared/constants';
import RolesGuard from './components/RolesGuard';
import Match from './pages/admin/Match';
import CreatePlayer from './pages/admin/CreatePlayer';
import CreateCompetitor from './pages/admin/CreateCompetitor';
import LeaguePlayers from './pages/LeaguePlayers';
import HowDoesThisWork from './pages/HowDoesThisWork';
import OfferTrade from './pages/OfferTrade';
import Trade from './pages/Trade';
import AllMatches from './pages/AllMatches';
import PlayerRankings from './pages/PlayerRankings';

// Antd only provides less files when customizing, so we've got
// to import those directly here before doing everything else in scss.
import 'antd/dist/antd.less';
import './styles/index.scss';

setupSentry();

export enum ROUTE {
  HOME = '/',
  AUTH = '/auth',
  CREATE_ACCOUNT = '/create_account',
  DISCOVER_LEAGUES = '/discover_leagues',
  MY_LEAGUES = '/my_leagues',
  CREATE_LEAGUE = '/create_league',
  LEAGUE = '/leagues/:leagueId',
  LEAGUE_PLAYERS = '/leagues/:leagueId/players',
  CREATE_FACTION = '/leagues/:leagueId/create_faction',
  FACTION = '/leagues/:leagueId/factions/:factionId',
  OFFER_TRADE = '/leagues/:leagueId/factions/:factionId/offer_trade',
  TRADE = '/leagues/:leagueId/trades/:tradeId',
  SET_DRAFT = '/leagues/:leagueId/set_draft',
  DRAFT = '/leagues/:leagueId/draft',
  MATCHES = '/matches',
  PLAYER_RANKINGS = '/player-rankings',
  HOW_DOES_THIS_WORK = '/how-does-this-work',
  ADMIN = '/admin',
  ADMIN_CREATE_PLAYER = '/admin/create_player',
  ADMIN_CREATE_COMPETITOR = '/admin/create_competitor',
  ADMIN_MATCH = '/admin/match',
}

export function routePath(
  route: ROUTE,
  params: { [key: string]: string }
): string {
  return Object.entries(params).reduce(
    (path, [paramId, paramValue]): string => {
      // replaceAll was throwing an error in some users browsers
      return path.split(`:${paramId}`).join(paramValue);
    },
    route.toString()
  );
}

const App: React.FC = (): ReactElement | null => {
  return (
    <Sentry.ErrorBoundary showDialog>
      <AppContextProvider>
        <Router>
          <Page>
            <Switch>
              <Route path={ROUTE.AUTH}>
                <SignIn />
              </Route>
              <Route path={ROUTE.HOW_DOES_THIS_WORK}>
                <HowDoesThisWork />
              </Route>
              <Redirect from={ROUTE.HOME} to={ROUTE.DISCOVER_LEAGUES} exact />
              <AuthGuard>
                <Route path={ROUTE.CREATE_ACCOUNT}>
                  <CreateAccount />
                </Route>
                <Route path={ROUTE.DISCOVER_LEAGUES} exact>
                  <DiscoverLeagues />
                </Route>
                <Route path={ROUTE.MY_LEAGUES} exact>
                  <MyLeagues />
                </Route>
                <Route path={ROUTE.MATCHES} exact>
                  <AllMatches />
                </Route>
                <Route path={ROUTE.PLAYER_RANKINGS} exact>
                  <PlayerRankings />
                </Route>
                <Route path={ROUTE.CREATE_LEAGUE} exact>
                  <CreateLeague />
                </Route>
                <Route path={ROUTE.LEAGUE} exact>
                  <League />
                </Route>
                <Route path={ROUTE.LEAGUE_PLAYERS} exact>
                  <LeaguePlayers />
                </Route>
                <Route path={ROUTE.CREATE_FACTION}>
                  <CreateFaction />
                </Route>
                <Route path={ROUTE.FACTION} exact>
                  <Faction />
                </Route>
                <Route path={ROUTE.OFFER_TRADE} exact>
                  <OfferTrade />
                </Route>
                <Route path={ROUTE.TRADE} exact>
                  <Trade />
                </Route>
                <Route path={ROUTE.SET_DRAFT}>
                  <SetDraft />
                </Route>
                <Route path={ROUTE.DRAFT}>
                  <Draft />
                </Route>
                <Route path="/admin">
                  <RolesGuard roles={[Role.ADMIN]}>
                    <Switch>
                      <Route path={ROUTE.ADMIN_CREATE_PLAYER} exact>
                        <CreatePlayer />
                      </Route>
                      <Route path={ROUTE.ADMIN_CREATE_COMPETITOR} exact>
                        <CreateCompetitor />
                      </Route>
                      <Route path={ROUTE.ADMIN_MATCH} exact>
                        <Match />
                      </Route>
                    </Switch>
                  </RolesGuard>
                </Route>
              </AuthGuard>
            </Switch>
          </Page>
        </Router>
      </AppContextProvider>
    </Sentry.ErrorBoundary>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
