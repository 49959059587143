import Model, { ModelData } from './Model';
import User, { UserData } from './User';

export interface TradeApprovalData extends ModelData {
  user: UserData;
  approved: boolean;
  fromInitiator: boolean;
}

export default class TradeApproval extends Model {
  readonly user: User;
  readonly approved: boolean;
  readonly fromInitiator: boolean;

  constructor(data: TradeApprovalData) {
    super(data);
    this.user = new User(data.user);
    this.approved = data.approved;
    this.fromInitiator = data.fromInitiator;
  }
}
