import React, { ReactElement, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, Input, Row } from 'antd';

import useFirebaseUser from '../hooks/useFirebaseUser';
import { useRedirectPath } from '../hooks/redirection';
import api from '../api';
import { useAppContext } from '../hooks/useAppContext';
import useFormWithDisableableSubmit from '../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../components/InputFormItem';
import { handleError } from '../utils';

interface iProps {}

type FormData = {
  username: string;
};

const CreateAccount: React.FC<iProps> = (): ReactElement | null => {
  const history = useHistory();
  const redirectPath = useRedirectPath();
  const firebaseUser = useFirebaseUser();
  const { setUser } = useAppContext();
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({
    username: firebaseUser?.displayName ?? undefined,
  });
  const [creatingAccount, setCreatingAccount] = useState(false);

  const handleCreateAccount = useCallback(
    (values: FormData) => {
      const { username } = values;

      setCreatingAccount(true);
      api
        .createUser(username)
        .then((user) => {
          setUser(user);
          history.push(redirectPath);
        })
        .catch((error) => {
          setCreatingAccount(false);
          handleError(error);
        });
    },
    [history, redirectPath, setUser]
  );

  return (
    <Row justify="center" className="page__vertically-centered">
      <Col className="inline-form-container">
        <Form
          form={form}
          layout="inline"
          onFinish={handleCreateAccount}
          onChange={handleFormChange}
        >
          <InputFormItem
            form={form}
            label="Username"
            name="username"
            rules={[{ required: true }]}
          >
            <Input disabled={creatingAccount} />
          </InputFormItem>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!canSubmit}
              loading={creatingAccount}
            >
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateAccount;
