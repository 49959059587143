import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin, Card } from 'antd';

import api from '../../api';
import useFormWithDisableableSubmit from '../../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../../components/InputFormItem';
import Player from '../../api/Player';
import { filterOption, handleError } from '../../utils';

type FormData = {
  name?: string;
  playerIds: string[];
};

const CreateCompetitor: React.FC = (): ReactElement | null => {
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({});
  const [creatingCompetitor, setCreatingCompetitor] = useState(false);
  const [players, setPlayers] = useState<Player[]>();

  useEffect(() => {
    api
      .getPlayers()
      .then((players) => {
        setPlayers(players);
      })
      .catch(handleError);
  }, []);

  const handleCreateCompetitor = useCallback(
    (values: FormData) => {
      const { name, playerIds } = values;

      setCreatingCompetitor(true);
      api
        .createCompetitor({ name, playerIds })
        .then(() => {
          form.resetFields();
        })
        .catch(handleError)
        .finally(() => {
          setCreatingCompetitor(false);
        });
    },
    [form]
  );
  if (!players) {
    return <Spin />;
  }

  return (
    <Row justify="center" className="set-draft">
      <Col span={16}>
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateCompetitor}
            onChange={handleFormChange}
          >
            <InputFormItem form={form} label="Name" name="name">
              <Input disabled={creatingCompetitor} />
            </InputFormItem>
            <InputFormItem
              form={form}
              label="Players"
              name="playerIds"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                onChange={handleFormChange}
                disabled={creatingCompetitor}
                showSearch
                filterOption={filterOption}
              >
                {players.map((player) => (
                  <Select.Option key={player.id} value={player.id}>
                    {player.name}
                  </Select.Option>
                ))}
              </Select>
            </InputFormItem>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!canSubmit}
                loading={creatingCompetitor}
              >
                Create Competitor
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateCompetitor;
