import React, { ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { AntDMenuInfo } from '../utils';

interface iActionMenuItem {
  title: string;
  action: () => void;
}

interface iLinkMenuItem {
  title: string;
  to: string;
}

interface iSubMenuItem {
  title: string;
  nestedItems: (iActionMenuItem | iLinkMenuItem)[];
}

export type iMenuItem = iActionMenuItem | iLinkMenuItem | iSubMenuItem;

const isSubMenuItem = (item: iMenuItem): item is iSubMenuItem => {
  return 'nestedItems' in item;
};

const isActionItem = (item: iMenuItem): item is iActionMenuItem => {
  return 'action' in item;
};

interface iProps {
  userName?: string;
  items: iMenuItem[];
}

const UserDropdownMenu: React.FC<iProps> = ({
  userName,
  items,
}): ReactElement | null => {
  const history = useHistory();

  const handleMenuItemClick = useCallback(
    (info: AntDMenuInfo) => {
      // The keypath comes backwards unintuitively.. reverse it.
      const keyPath = info.keyPath.reverse() as number[];
      let item = items[keyPath[0]];
      if (isSubMenuItem(item)) {
        item = item.nestedItems[keyPath[1]];
      }
      if (isActionItem(item)) {
        item.action();
      } else {
        history.push(item.to);
      }
    },
    [items, history]
  );

  const preventDefault = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault(),
    []
  );

  return (
    <Dropdown
      overlay={
        <Menu onClick={handleMenuItemClick}>
          {items.map((item, i) =>
            !isSubMenuItem(item) ? (
              <Menu.Item key={i}>{item.title}</Menu.Item>
            ) : (
              <Menu.SubMenu key={i} title={item.title}>
                {item.nestedItems.map((subItem, j) => (
                  <Menu.Item key={j}>{subItem.title}</Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          )}
        </Menu>
      }
    >
      <Button type="link" onClick={preventDefault}>
        {userName || ''} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default UserDropdownMenu;
