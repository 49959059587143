import React, { ReactElement, useCallback } from 'react';
import { List, Spin } from 'antd';

import League from '../api/League';
import LeagueRow from './LeagueRow';
import { useAppContext } from '../hooks/useAppContext';
import { PaginationProps, useLoadingFacades } from '../hooks/PaginationHooks';

interface iProps {
  header?: React.ReactNode;
  leagues?: League[];
  paginationProps: PaginationProps;
}

const LeagueList: React.FC<iProps> = ({
  header,
  leagues,
  paginationProps,
}): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();

  const renderDiscoverRow = useCallback(
    (league: League) => {
      return user && <LeagueRow league={league} user={user} />;
    },
    [user]
  );

  const {
    itemsWithLoadingFacades: leaguesWithLoadingFacades,
    renderWithLoadingFacade,
  } = useLoadingFacades(
    renderDiscoverRow,
    leagues,
    paginationProps,
    'league-row'
  );

  if (!user) {
    return <Spin />;
  }

  return (
    <List
      header={header}
      dataSource={leaguesWithLoadingFacades}
      loading={!leaguesWithLoadingFacades}
      renderItem={renderWithLoadingFacade}
      locale={{ emptyText: 'No Leagues' }}
      loadMore={paginationProps.loadMoreElement}
    />
  );
};

export default LeagueList;
