import { Dayjs } from 'dayjs';

import Faction, { FactionData } from './Faction';
import Model, { ModelData } from './Model';
import PlayerFactionMembership, {
  PlayerFactionMembershipData,
} from './PlayerFactionMembership';
import dayjs from '../utils/dayjsInstance';

export interface DraftPickData extends ModelData {
  pickNumber: number;
  faction: FactionData;
  playerFactionMembership?: PlayerFactionMembershipData;
  deadline?: string;
  round: number;
}

export default class DraftPick extends Model {
  readonly pickNumber: number;
  readonly faction: Faction;
  readonly playerFactionMembership?: PlayerFactionMembership;
  readonly deadline?: Dayjs;
  readonly round: number;

  constructor(data: DraftPickData) {
    super(data);
    this.pickNumber = data.pickNumber;
    this.faction = new Faction(data.faction);
    this.playerFactionMembership =
      data.playerFactionMembership &&
      new PlayerFactionMembership(data.playerFactionMembership);
    this.deadline = data.deadline ? dayjs(data.deadline) : undefined;
    this.round = data.round;
  }

  get hasBeenUsed(): boolean {
    return !!this.playerFactionMembership;
  }
}
