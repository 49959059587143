import sortBy from 'lodash.sortby';

import FactionRosterSpot, { FactionRosterSpotData } from './FactionRosterSpot';
import Model, { ModelData } from './Model';
import User, { UserData } from './User';

export interface FactionData extends ModelData {
  name: string;
  manager: UserData;
  factionRosterSpots: FactionRosterSpotData[];
  pointTotal?: number;
}

export default class Faction extends Model {
  readonly name: string;
  readonly manager: User;
  readonly factionRosterSpots: FactionRosterSpot[];
  readonly pointTotal?: number;

  constructor(data: FactionData) {
    super(data);
    this.name = data.name;
    this.manager = new User(data.manager);
    this.factionRosterSpots = sortBy(
      data.factionRosterSpots.map((frsd) => new FactionRosterSpot(frsd)),
      (rosterSpot) => rosterSpot.rosterNumber
    );
    this.pointTotal = data.pointTotal;
  }

  get pointsDisplay(): string {
    return this.pointTotal?.toString() || '--';
  }
}
