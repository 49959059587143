import React, { useCallback, useContext, useReducer } from 'react';
import * as Sentry from '@sentry/react';

import User from '../api/User';

interface State {
  user?: User;
}

enum ActionType {
  SET_USER = 'SET_USER',
  UNSET_USER = 'UNSET_USER',
}

interface SetUserAction {
  type: ActionType.SET_USER;
  user: User;
}

interface UnsetUserAction {
  type: ActionType.UNSET_USER;
}

type Action = SetUserAction | UnsetUserAction;

interface iAppContext {
  state: State;
  setUser: (user: User) => void;
  unsetUser: () => void;
}

const AppContext = React.createContext<iAppContext>({} as iAppContext);

const appReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case ActionType.UNSET_USER:
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
};

export const AppContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, {});

  const setUser = useCallback((user: User) => {
    Sentry.setUser({ id: user.id, username: user.username });

    dispatch({
      type: ActionType.SET_USER,
      user,
    });
  }, []);

  const unsetUser = useCallback(() => {
    Sentry.setUser(null);

    dispatch({
      type: ActionType.UNSET_USER,
    });
  }, []);

  return (
    <AppContext.Provider value={{ state, setUser, unsetUser }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): iAppContext => {
  return useContext(AppContext);
};
