import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  List,
  Modal,
  Row,
  Space,
  Spin,
  Transfer,
  Typography,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { notification } from 'antd';

import api from '../api';
import { useAppContext } from '../hooks/useAppContext';
import Faction from '../api/Faction';
import League from '../api/League';
import { handleError } from '../utils';
import BackLink from '../components/BackLink';
import { ROUTE, routePath } from '..';
import PlayerFactionMembership from '../api/PlayerFactionMembership';
import InputFormItem from '../components/InputFormItem';
import useFormWithDisableableSubmit from '../hooks/useFormWithDisableableSubmit';
import dayjs from '../utils/dayjsInstance';
import DatePicker from '../components/DatePicker';

interface Params {
  leagueId: string;
  factionId: string;
}

type FormData = {
  expiryTime: Dayjs;
};

interface MembershipDivision {
  allOfferingFactionMemberships: PlayerFactionMembership[];
  allRequestedFactionMemberships: PlayerFactionMembership[];
}

interface iProps {}

const OfferTrade: React.FC<iProps> = (): ReactElement | null => {
  const {
    state: { user },
  } = useAppContext();
  const { leagueId, factionId } = useParams<Params>();
  const history = useHistory();
  const [league, setLeague] = useState<League>();
  const [requestedFaction, setRequestedFaction] = useState<Faction>();
  const [offeringFaction, setOfferingFaction] = useState<Faction>();
  const [
    allOfferingFactionMemberships,
    setAllOfferingFactionMemberships,
  ] = useState<PlayerFactionMembership[]>();
  const [
    allRequestedFactionMemberships,
    setAllRequestedFactionMemberships,
  ] = useState<PlayerFactionMembership[]>();
  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({
    // Making the expiry time a little over 24 hours from now
    // avoids a bug in the datepicker where today isn't shown as an
    // available day to pick.
    expiryTime: dayjs().add(24, 'hours').add(5, 'minutes').set('second', 0),
  });
  const [submittingTrade, setSubmittingTrade] = useState(false);

  useEffect(() => {
    api
      .getFaction(leagueId, factionId)
      .then((faction) => {
        setRequestedFaction(faction);
        setAllRequestedFactionMemberships(
          faction.factionRosterSpots
            .map((rosterSpot) => rosterSpot.playerFactionMembership)
            .filter(
              (membership): membership is PlayerFactionMembership =>
                !!membership
            )
        );
      })
      .catch(handleError);
  }, [leagueId, factionId]);

  useEffect(() => {
    const faction = league?.factions.find(
      (faction) => faction.manager.id === user?.id
    );
    setOfferingFaction(faction);
    setAllOfferingFactionMemberships(
      faction?.factionRosterSpots
        .map((rosterSpot) => rosterSpot.playerFactionMembership)
        .filter(
          (membership): membership is PlayerFactionMembership => !!membership
        )
    );
  }, [league?.factions, user?.id]);

  useEffect(() => {
    api
      .getLeague(leagueId)
      .then((league) => {
        setLeague(league);
      })
      .catch(handleError);
  }, [leagueId]);

  const requestedMemberships = useMemo(() => {
    if (!(allOfferingFactionMemberships && offeringFaction)) {
      return undefined;
    }

    return allOfferingFactionMemberships.filter((membership) =>
      offeringFaction.factionRosterSpots.every(
        (rosterSpot) => rosterSpot.playerFactionMembership?.id !== membership.id
      )
    );
  }, [allOfferingFactionMemberships, offeringFaction]);

  const offeredMemberships = useMemo(() => {
    if (!(allRequestedFactionMemberships && requestedFaction)) {
      return undefined;
    }

    return allRequestedFactionMemberships.filter((membership) =>
      requestedFaction.factionRosterSpots.every(
        (rosterSpot) => rosterSpot.playerFactionMembership?.id !== membership.id
      )
    );
  }, [allRequestedFactionMemberships, requestedFaction]);

  const submitTradeOffer = useCallback(() => {
    const { expiryTime } = form.getFieldsValue();

    if (
      !(
        offeringFaction &&
        requestedFaction &&
        offeredMemberships &&
        requestedMemberships
      )
    ) {
      handleError(
        new Error('Something went wrong when trying to submit the trade offer.')
      );
      return;
    }

    const offeredMembershipIds = offeredMemberships.map(
      (membership) => membership.id
    );

    const requestedMembershipIds = requestedMemberships.map(
      (membership) => membership.id
    );

    setSubmittingTrade(true);
    api
      .offerTrade({
        leagueId,
        expiryTime,
        offeringFromFactionId: offeringFaction?.id,
        requestingFromFactionId: requestedFaction?.id,
        offeringPlayerMembershipIds: offeredMembershipIds,
        requestingPlayerMembershipIds: requestedMembershipIds,
      })
      .then((trade) => {
        notification['success']({
          message: 'Trade offer submitted',
        });
        history.push(routePath(ROUTE.TRADE, { leagueId, tradeId: trade.id }));
      })
      .catch(handleError)
      .finally(() => setSubmittingTrade(false));
  }, [
    leagueId,
    offeringFaction,
    requestedFaction,
    offeredMemberships,
    requestedMemberships,
    form,
    history,
  ]);

  const renderMembersipConfirmationItem = useCallback(
    (membership: PlayerFactionMembership) => (
      <List.Item>
        <Typography.Text>{membership.player.name}</Typography.Text>
      </List.Item>
    ),
    []
  );

  const confirmTradeOffer = useCallback(() => {
    if (!(offeredMemberships && requestedMemberships)) {
      handleError(
        new Error('Must must players between factions to offer a trade')
      );
      return;
    }

    Modal.confirm({
      title: 'Do you want to submit this trade offer?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Row justify="space-around" gutter={16}>
          <Col span={12}>
            <List
              header="Sending"
              bordered
              size="small"
              dataSource={offeredMemberships}
              renderItem={renderMembersipConfirmationItem}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <List
              header="Receiving"
              bordered
              size="small"
              dataSource={requestedMemberships}
              renderItem={renderMembersipConfirmationItem}
              pagination={false}
            />
          </Col>
        </Row>
      ),
      onOk: submitTradeOffer,
    });
  }, [
    offeredMemberships,
    requestedMemberships,
    submitTradeOffer,
    renderMembersipConfirmationItem,
  ]);

  const renderMembersip = useCallback(
    (membership: PlayerFactionMembership) => membership.player.name,
    []
  );

  const filterOption = useCallback(
    (search: string, membership: PlayerFactionMembership) =>
      membership.player.name.toLowerCase().includes(search.toLowerCase()),
    []
  );

  const getRowKey = useCallback((memership) => memership.id, []);

  const allMemberships = useMemo(
    () => [
      ...(allOfferingFactionMemberships || []),
      ...(allRequestedFactionMemberships || []),
    ],
    [allOfferingFactionMemberships, allRequestedFactionMemberships]
  );

  const onChange = useCallback(
    (requestedFactionMembershipIds: string[]) => {
      const membershipDivision = allMemberships.reduce(
        (accMembershipDivision: MembershipDivision, membership) => {
          if (requestedFactionMembershipIds.includes(membership.id)) {
            return {
              ...accMembershipDivision,
              allRequestedFactionMemberships: [
                ...accMembershipDivision.allRequestedFactionMemberships,
                membership,
              ],
            };
          } else {
            return {
              ...accMembershipDivision,
              allOfferingFactionMemberships: [
                ...accMembershipDivision.allOfferingFactionMemberships,
                membership,
              ],
            };
          }
        },
        {
          allOfferingFactionMemberships: [],
          allRequestedFactionMemberships: [],
        }
      );

      setAllOfferingFactionMemberships(
        membershipDivision.allOfferingFactionMemberships
      );
      setAllRequestedFactionMemberships(
        membershipDivision.allRequestedFactionMemberships
      );
    },
    [allMemberships]
  );

  const isDateInvalid = useCallback((date: Dayjs): boolean => {
    return date.isBefore(dayjs());
  }, []);

  if (!league || !requestedFaction || !user) {
    return <Spin />;
  }

  if (!offeringFaction) {
    return (
      <Row justify="center">
        <Alert
          message="You can't offer a trade to this faction because you don't have a faction in this league."
          type="error"
        />
      </Row>
    );
  }

  return (
    <Row className="offer-trade" justify="center">
      <Col span={24} lg={16}>
        <Card
          title={
            <Space>
              <BackLink
                to={routePath(ROUTE.FACTION, { leagueId, factionId })}
              />
              <span>Offer a trade to {requestedFaction.name}</span>
            </Space>
          }
        >
          <Space direction="vertical" size="large" className="space-wrapper">
            <Row justify="center">
              <Typography.Text>
                Note: Player's are only eligible to accumulate points for a
                faction at 12:00AM PST the day after a trade has been executed.
              </Typography.Text>
            </Row>
            <Row justify="center">
              <Transfer
                dataSource={allMemberships}
                targetKeys={allRequestedFactionMemberships?.map(
                  (membership) => membership.id
                )}
                titles={[offeringFaction.name, requestedFaction.name]}
                onChange={onChange}
                render={renderMembersip}
                rowKey={getRowKey}
                listStyle={{
                  width: 250,
                  height: 600,
                }}
                locale={{
                  itemUnit: 'player',
                  itemsUnit: 'players',
                  notFoundContent: 'No players',
                }}
                showSearch
                filterOption={filterOption}
              />
            </Row>
            <Row justify="center">
              <Form form={form} layout="inline" onChange={handleFormChange}>
                <InputFormItem
                  className="offer-trade__expiry-time"
                  form={form}
                  label="Expiry Time"
                  name="expiryTime"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    showTime
                    showNow={false}
                    format="MMMM Do YYYY, h:mm a"
                    disabledDate={isDateInvalid}
                    disabled={submittingTrade}
                    onChange={handleFormChange}
                  />
                </InputFormItem>
              </Form>
            </Row>
            <Row justify="center">
              <Button
                type="primary"
                onClick={confirmTradeOffer}
                disabled={
                  !offeredMemberships?.length ||
                  !requestedMemberships?.length ||
                  !canSubmit
                }
                loading={submittingTrade}
              >
                Offer Trade
              </Button>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default OfferTrade;
