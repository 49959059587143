import { Dayjs } from 'dayjs';

import dayjs from '../utils/dayjsInstance';
import { Division, MatchType } from '../shared/constants';
import { displayEnumValue } from '../utils';
import MatchCompetitor, { MatchCompetitorData } from './MatchCompetitor';
import Model, { ModelData } from './Model';
import Season, { SeasonData } from './Season';

export interface MatchData extends ModelData {
  date: string;
  division: Division;
  season: SeasonData;
  matchType: MatchType;
  matchCompetitors: MatchCompetitorData[];
  pointsEarned?: number;
}

export default class Match extends Model {
  readonly date: Dayjs;
  readonly division: Division;
  readonly season: Season;
  readonly matchType: MatchType;
  readonly matchCompetitors: MatchCompetitor[];
  /**
   * Depending on the context either:
   *
   * Points for the faction that this match was loaded for, only
   * if it was loaded for a given faction.
   *
   * OR
   * Points for the player that this match was loaded for, only
   * if it was loaded for a given player.
   */
  readonly pointsEarned?: number;

  constructor(data: MatchData) {
    super(data);
    this.date = dayjs(data.date);
    this.division = data.division;
    this.season = new Season(data.season);
    this.matchType = data.matchType;
    this.matchCompetitors = data.matchCompetitors?.map(
      (matchCompetitor) => new MatchCompetitor(matchCompetitor)
    );
    this.pointsEarned = data.pointsEarned;
  }

  get divisionName(): string {
    return displayEnumValue(this.division);
  }

  get matchTypeName(): string {
    return displayEnumValue(this.matchType);
  }
}
