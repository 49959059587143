import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { Button, Col, Form, Row, Select, Modal } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import dayjs from '../utils/dayjsInstance';
import { ROUTE, routePath } from '..';
import api from '../api';
import useFormWithDisableableSubmit from '../hooks/useFormWithDisableableSubmit';
import InputFormItem from '../components/InputFormItem';
import DatePicker from '../components/DatePicker';
import {
  MAX_MINUTES_PER_DRAFT_PICK,
  MINUTES_PER_DRAFT_PICK_DIVISIBLE_BY,
  MIN_MINUTES_PER_DRAFT_PICK,
} from '../shared/constants';
import { handleError } from '../utils';
const { confirm } = Modal;

interface Params {
  leagueId: string;
}

type FormData = {
  startTime: Dayjs;
  minutesPerPick: number;
};

interface PickClockOption {
  minutesPerPick: number;
  displayValue: string;
}

const SetDraft: React.FC = (): ReactElement | null => {
  const { leagueId } = useParams<Params>();
  const history = useHistory();

  const [
    form,
    canSubmit,
    handleFormChange,
  ] = useFormWithDisableableSubmit<FormData>({
    startTime: dayjs().add(10, 'minutes').set('second', 0),
    minutesPerPick: MIN_MINUTES_PER_DRAFT_PICK,
  });
  const [creatingDraft, setCreatingDraft] = useState(false);

  const handleCreateDraft = useCallback(
    (values: FormData) => {
      const { startTime, minutesPerPick } = values;

      setCreatingDraft(true);
      api
        .createDraft(leagueId, startTime, minutesPerPick)
        .then(() => {
          history.push(routePath(ROUTE.LEAGUE, { leagueId }));
        })
        .catch((error) => {
          setCreatingDraft(false);
          handleError(error);
        });
    },
    [leagueId, history]
  );

  const confirmCreateDraft = useCallback(
    (values: FormData) => {
      confirm({
        title: "Are you sure you want to lock in this league's draft time?",
        icon: <WarningFilled />,
        content:
          "Once a draft date has been set, no more factions will be able to join this league. This can't be undone.",
        onOk() {
          handleCreateDraft(values);
        },
      });
    },
    [handleCreateDraft]
  );

  const isDateInvalid = useCallback((date: Dayjs): boolean => {
    return date.isBefore(dayjs(), 'day');
  }, []);

  const pickClockOptions: PickClockOption[] = useMemo(() => {
    const numberOfIncrements =
      (MAX_MINUTES_PER_DRAFT_PICK - MIN_MINUTES_PER_DRAFT_PICK) /
      MINUTES_PER_DRAFT_PICK_DIVISIBLE_BY;

    return [...Array(numberOfIncrements + 1)].map((_, i) => {
      const minutesPerPick =
        MIN_MINUTES_PER_DRAFT_PICK + i * MINUTES_PER_DRAFT_PICK_DIVISIBLE_BY;
      let displayValue = '';
      if (minutesPerPick < 1) {
        displayValue = `${minutesPerPick * 60} seconds`;
      } else {
        const incompleteMinutes = minutesPerPick % 1;
        if (incompleteMinutes === 0) {
          if (minutesPerPick === 1) {
            displayValue = `${minutesPerPick} minute`;
          } else {
            displayValue = `${minutesPerPick} minutes`;
          }
        } else {
          displayValue = `${minutesPerPick - incompleteMinutes} minutes and ${
            incompleteMinutes * 60
          } seconds`;
        }
      }
      return {
        minutesPerPick,
        displayValue,
      };
    });
  }, []);

  return (
    <Row justify="center" className="page__vertically-centered set-draft">
      <Col>
        <Form
          form={form}
          layout="vertical"
          onFinish={confirmCreateDraft}
          onChange={handleFormChange}
        >
          <InputFormItem
            form={form}
            label="Start time"
            name="startTime"
            rules={[{ required: true }]}
          >
            <DatePicker
              className="set-draft__start-time"
              showTime
              showNow={false}
              format="MMMM Do YYYY, h:mm a"
              disabledDate={isDateInvalid}
              disabled={creatingDraft}
              onChange={handleFormChange}
            />
          </InputFormItem>
          <InputFormItem form={form} label="Pick clock" name="minutesPerPick">
            <Select disabled={creatingDraft} onChange={handleFormChange}>
              {pickClockOptions.map((option) => (
                <Select.Option
                  key={option.minutesPerPick}
                  value={option.minutesPerPick}
                >
                  {option.displayValue}
                </Select.Option>
              ))}
            </Select>
          </InputFormItem>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!canSubmit}
              loading={creatingDraft}
            >
              Set Draft
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SetDraft;
