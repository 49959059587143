import React, { ReactElement } from 'react';
import { Button } from 'antd';

interface iProps {
  loadMore: () => void;
}

const LoadMore: React.FC<iProps> = ({ loadMore }): ReactElement | null => {
  return (
    <div className="load-more">
      <Button onClick={loadMore}>Load more</Button>
    </div>
  );
};

export default LoadMore;
