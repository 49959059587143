import { Affix, Button, Card, Table, Grid } from 'antd';
import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useMemo,
} from 'react';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import scrollIntoView from 'scroll-into-view';

import DraftPick from '../api/DraftPick';
import Draft from '../api/Draft';
import PlayerDetailButton from './PlayerDetailButton';

const { useBreakpoint } = Grid;

interface iProps {
  draft: Draft;
  nextPick?: DraftPick;
  pickPlayer: () => void;
  currentUserId: string;
  draftHeaderHeight: number;
}

const ROUND_HEADER_OFFSET = 85;

const DraftRounds: React.FC<iProps> = ({
  draft,
  nextPick,
  pickPlayer,
  currentUserId,
  draftHeaderHeight,
}): ReactElement | null => {
  const rounds: DraftPick[][] = useMemo(() => {
    return sortBy(
      Object.values(groupBy(draft.orderedDraftPicks, (pick) => pick.round)),
      (round) => round[0].round
    ).map((round) => sortBy(round, (pick) => pick.pickNumber));
  }, [draft.orderedDraftPicks]);
  const screens = useBreakpoint();

  useLayoutEffect(() => {
    if (nextPick) {
      const pickOnTheClock = document.querySelector<HTMLElement>(
        '.draft-rounds__pick--on-the-clock'
      );
      if (pickOnTheClock) {
        scrollIntoView(pickOnTheClock, {
          align: {
            topOffset: draftHeaderHeight + ROUND_HEADER_OFFSET,
            top: 0,
          },
        });
      }
    }
  }, [nextPick, draftHeaderHeight]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Pick #',
        dataIndex: 'pickNumber',
      },
      {
        title: 'Faction',
        dataIndex: ['faction', 'id'],
        render: (_text: string, pick: DraftPick): string => {
          return pick.faction.name;
        },
      },
      {
        title: 'Player',
        dataIndex: 'id',
        render: (_text: string, pick: DraftPick): React.ReactNode => {
          if (pick.playerFactionMembership) {
            return (
              <PlayerDetailButton
                player={pick.playerFactionMembership.player}
              />
            );
          }
          // The way antd's breakpoints work, this means we'll show this button if
          // we're below a "small" screen size
          if (!screens.sm && currentUserId === pick.faction.manager.id) {
            return (
              <Button type="primary" onClick={pickPlayer}>
                {draft.hasStarted && nextPick?.id === pick.id
                  ? 'Pick Player'
                  : 'View Players'}
              </Button>
            );
          }
          return '--';
        },
      },
    ];
  }, [pickPlayer, currentUserId, nextPick?.id, screens.sm, draft]);

  const getRowClassName = useCallback(
    (draftPick) => {
      let className = 'draft-rounds__pick';
      if (draftPick.hasBeenUsed) {
        className += ' draft-rounds__pick--selected';
      } else if (nextPick?.id === draftPick.id && draft.hasStarted) {
        className += ' draft-rounds__pick--on-the-clock';
      } else {
        className += ' draft-rounds__pick--unused';
      }
      return className;
    },
    [draft, nextPick]
  );

  return (
    <div className="draft-rounds">
      {rounds.map((round, i) => (
        <React.Fragment key={i}>
          <Affix offsetTop={draftHeaderHeight}>
            <div className="draft-rounds__round-header-container">
              <Card className="draft-rounds__round-header">Round {i + 1}</Card>
            </div>
          </Affix>
          <Table
            showHeader={false}
            key={i}
            dataSource={round}
            columns={columns}
            rowClassName={getRowClassName}
            rowKey="id"
            pagination={false}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default DraftRounds;
