import Model, { ModelData } from './Model';

export interface SeasonData extends ModelData {
  number: number;
  isEnded: boolean;
}

export default class Season extends Model {
  readonly number: number;
  readonly isEnded: boolean;

  constructor(data: SeasonData) {
    super(data);
    this.number = data.number;
    this.isEnded = data.isEnded;
  }
}
