import Icon from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const MotySvg: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="182px"
    height="38px"
    viewBox="0 0 1820 380"
    preserveAspectRatio="xMidYMid meet"
  >
    <g id="layer101" fill="#3292e6" stroke="none">
      <path d="M110 346 c0 -2 16 -14 35 -26 31 -19 35 -26 35 -64 0 -37 -4 -43 -32 -58 -18 -10 -48 -19 -67 -20 -66 -6 -93 -86 -36 -108 17 -7 21 -14 18 -34 l-5 -26 136 0 136 0 0 24 c0 14 10 30 25 40 14 9 25 26 25 37 0 31 -44 69 -80 69 -52 0 -80 26 -80 74 0 34 5 46 32 68 l33 27 -87 1 c-49 0 -88 -2 -88 -4z m-12 -193 c-3 -5 -10 -23 -17 -40 -14 -36 -28 -42 -38 -17 -9 24 14 64 38 64 11 0 19 -3 17 -7z m240 -5 c16 -16 15 -55 -2 -61 -8 -3 -19 8 -30 29 -9 19 -16 37 -16 39 0 10 37 4 48 -7z" />
    </g>
    <g id="layer102" fill="#ff8a22" stroke="none">
      <path d="M900 359 c-57 -23 -60 -33 -60 -161 0 -66 5 -128 11 -140 23 -43 134 -62 222 -37 52 14 67 52 66 166 -1 109 -7 131 -42 157 -32 24 -154 33 -197 15z m125 -169 l0 -85 -40 0 -40 0 -3 65 c-5 98 1 112 46 108 l37 -3 0 -85z" />
      <path d="M430 190 l0 -170 53 0 54 0 27 50 c15 28 31 50 35 50 4 0 20 -22 35 -50 l27 -50 55 0 54 0 0 171 0 170 -52 -3 -53 -3 -3 -89 -3 -90 -19 32 c-27 44 -63 44 -87 0 l-18 -33 -3 93 -3 92 -49 0 -50 0 0 -170z" />
      <path d="M1268 233 l-3 -128 -42 -3 c-43 -3 -43 -3 -43 -43 l0 -39 145 0 145 0 0 40 0 40 -45 0 -45 0 0 130 0 130 -55 0 -54 0 -3 -127z" />
      <path d="M1610 327 c0 -23 -14 -52 -50 -101 -48 -67 -50 -71 -50 -137 l0 -69 55 0 55 0 0 58 c0 41 6 64 20 82 18 24 20 24 37 7 12 -12 20 -38 23 -80 l5 -62 53 -3 52 -3 0 55 c0 68 -9 93 -60 160 -30 39 -40 62 -40 89 l0 37 -50 0 c-50 0 -50 0 -50 -33z" />
    </g>
  </svg>
);

interface iProps {}

const Logo: React.FC<iProps> = (props) => (
  <Icon component={MotySvg} className="logo" alt="MOTY" {...props} />
);

export default Logo;
