import { notification } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import debounce from 'lodash.debounce';

import NOTIFICATION_SOUND from '../assets/notification-sound.mp3';

export enum KeyCode {
  // Add as needed
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

const capitilize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

const camelCaseLessVar = (key: string): string => {
  const [firstSegment, ...segments] = key.replace('@', '').split('-');
  const capitalizedSegments = segments.map(capitilize);
  return [firstSegment, capitalizedSegments].join('');
};

type CustomAntdTheme = {
  primaryColor: string;
  linkColor: string;
  successColor: string;
  warningColor: string;
  errorColor: string;
  infoColor: string;
  fontSizeBase: string;
  layoutHeaderBackground: string;
  layoutFooterBackground: string;
};

export const CUSTOM_ANTD_THEME: CustomAntdTheme = Object.fromEntries(
  Object.entries(process.env.CUSTOM_ANTD_THEME!).map(([key, value]) => {
    return [camelCaseLessVar(key), value];
  })
) as CustomAntdTheme;

export const displayEnumValue = (enumValue: string): string => {
  return enumValue.split('_').map(capitilize).join(' ');
};

// Don't spam users with error report dialogs if they get a burst of errors.
const showErrorReportDialog = debounce(Sentry.showReportDialog, 5000, {
  leading: true,
  trailing: false,
});

export const setupSentry = (): void => {
  Sentry.init({
    environment: process.env.REACT_APP_APP_ENV,
    dsn:
      'https://f0b9839774744b4490a1e36ad25746ca@o524122.ingest.sentry.io/5636594',
    integrations: [new Integrations.BrowserTracing()],
    // These ResizeObserver errors are known to be harmless, don't annoy people with them.
    ignoreErrors: [/ResizeObserver/],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        showErrorReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    tracesSampleRate: 0.3,
  });
};

const isAxiosError = (error: object): error is AxiosError => {
  return 'isAxiosError' in error;
};

export const handleError = (error: Error | AxiosError): void => {
  let message: string | undefined;
  let severity: Sentry.Severity = Sentry.Severity.Warning;

  if (isAxiosError(error)) {
    message = error.response?.data.message || error.message;
    if (error.response && error.response.status >= 500) {
      severity = Sentry.Severity.Error;
    }
  } else {
    severity = Sentry.Severity.Error;
    message = error.message;
  }
  message = message || 'Unexpected error';

  // Log error to sentry. Purposely not using captureException as exceptions
  // trigger our gloabal error report diaolog.
  Sentry.captureMessage(
    `${message} ${error.stack?.split('@').join('\n')}`,
    severity
  );

  // Show error notification
  notification['error']({
    message: 'Error',
    description: message,
  });
};

export const filterOption: Exclude<
  SelectProps<string>['filterOption'],
  undefined | boolean
> = (search, option): boolean => {
  // Not sure whats going on with the type here, this is a bit
  // of a clusterfuck.. but it seems to filter just fine
  return option?.children.toLowerCase().includes(search.toLowerCase());
};

// Not exported from antd for some reason
export interface AntDMenuInfo {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
}

let audio: HTMLAudioElement | null;

export const makeNotificationSound = (): void => {
  if (!audio) {
    audio = new Audio(NOTIFICATION_SOUND);
  }

  audio.play().catch((error: Error) => {
    // Log warning to sentry. Purposely not using captureException as exceptions
    // trigger our gloabal error report diaolog.
    Sentry.captureMessage(error.message, Sentry.Severity.Warning);
  });
};
