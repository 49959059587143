import React, { ReactElement, useMemo } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

import League from '../api/League';
import User from '../api/User';
import { ROUTE, routePath } from '..';
import LeagueCommisioner from './LeagueCommisioner';
import LeagueFactionCapacity from './LeagueFactionCapacity';

interface iProps {
  league: League;
  user: User;
}

const LeagueRow: React.FC<iProps> = ({ league, user }): ReactElement | null => {
  const canYouJoin = useMemo(() => {
    return league.canUserJoin(user);
  }, [league, user]);
  const leaguePath = routePath(ROUTE.LEAGUE, { leagueId: league.id });

  return (
    <Card
      className="league-row"
      title={league.name}
      extra={
        <Row>
          <Typography.Text>
            <LeagueCommisioner league={league} currentUser={user} />
          </Typography.Text>
        </Row>
      }
    >
      <Row align="middle">
        <Col span={18}>
          <LeagueFactionCapacity league={league} />
        </Col>
        <Col span={2} />
        <Col span={4}>
          {canYouJoin ? (
            <Link className="ant-btn ant-btn-primary" to={leaguePath}>
              Join
            </Link>
          ) : (
            <Link className="ant-btn ant-btn-default" to={leaguePath}>
              View
            </Link>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default LeagueRow;
